/*
* components.module.ts
* 21-1-2022 - Jelmer Jellema - Spin in het Web B.V.
*/

import {BierComponent} from "./bier/bier";
import {NgModule} from "@angular/core";
import {BasisModule} from "../basis.module";
import {NavbarComponent} from "./navbar/navbar";
import {PersoonComponent} from "./persoon/persoon";

@NgModule({
  declarations: [
    BierComponent,
    PersoonComponent,
    NavbarComponent
  ],
  exports: [
    BierComponent,
    PersoonComponent,
    NavbarComponent
  ],
  imports: [BasisModule]
})
export class ComponentsModule {}
