/*
* signup.ts
* 26-11-2021 - Jelmer Jellema - Spin in het Web B.V.
*/

import {BeerproPage} from "../../../abstracts/beerpropage";
import {Component, Injector} from "@angular/core";

@Component({
  templateUrl: './signup.html',
  styleUrls: ['../loginflow.scss']
})
export class SignupPage extends BeerproPage {

  constructor(inject: Injector) {
    super(inject, 'debug');
  }

  //todo:
  loginFacebook() {}
  fbFeedback: string = "";
}
