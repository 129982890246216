/**
 <persoon [persoon]="stdgebruiker... of id"
 [push]=<false|true|"detail"> [vriendstatus]=<boolean: false
 [proef]=<boolean: true, toon proefdata>
 [disablezelf]=<boolean: false> Disable button met zelf
 [itemclass]=<string, voeg class aan het item toe>
 [itemclass-prop]=<string, haal de toe te voegen class uit een ._meta property van de persoon>
 [end-icon]<ion-icon-name=null string>
 [end-icon-prop]=<string> haal het end-icon uit een ._meta property van de persoon
 > (itemclick)="<functie>" >
 [template met extra dingen bij persoon in item]
 </persoon>
 Standaard afbeelden van een persoon. persoon bevat de std-gebruikerdata of een speciaal construct voor geen beerpro-gebruiker.
 [slideIcon]=<string, als gegeven dan kan het item naar links sliden (als het in een ion-list zit) en toont het bij slide dit icon + het itemswipe event
 [slideColor]=<string, afwijkende kleur voor het slideicon. Bijvoorbeeld 'danger'

 Als push "detail" is, dan wordt het detailscherm van deze user gepusht. BE bepaalt wel of dat mag.
 Push is standaard false

 Geen beerpro-gebruiker:
 Als het persoonobject een naam heeft kan hij afgebeeld worden (kan ook een e-mailadres zijn) Als er geen .profilenaam is, wordt deze niet afgebeeld.
 Als de gebruiker een veld "extra" bevat, wordt die afgebeeld onder de profilenaam
 **/

import {Component, EventEmitter, Injector, Input, Output, ViewChild} from "@angular/core";
import * as moment from 'moment';
import {BeerproComponent} from "../../abstracts/beerprocomponent";
import {IonItemSliding} from "@ionic/angular";
import {persoon} from "../../beerprotypes";

@Component({
  selector: 'persoon',
  templateUrl: './persoon.html',
  styleUrls: ['./persoon.scss']
})
export class PersoonComponent extends BeerproComponent {
  @Input() disablezelf: boolean = false; //disable button als zelf
  @Input() push: boolean | 'detail' = false; //toon push-icon
  @Input() vriendstatus: boolean = false; //toon vriendstatus
  @Input() proef: boolean = true; //toon proefdata
  @Output() itemclick = new EventEmitter<any>();
  @Input('end-icon') endIcon: string; //toon een ion-icon op de plek van de push. Push én end-icon aangeven is vragen om problemen
  @Input('end-icon-prop') endIconProp: string; //property van de persoon met het af te beelden icon. Niet combineren met push en end-icon
  @Input() itemclass: string; //extra class als die er is
  @Input('itemclass-prop') itemclassProp: string; //of een prop die dat regels
  @Input() disabled: boolean = false; //disable het event
  @Input() slideIcon: string = null; //als gegeven dan kan het item naar links sliden (als het in een ion-list zit) en toont het bij slide dit icon + het itemswipe event
  @Input() slideColor: string = null; //afwijkende kleur voor het slideicon. Bijvoorbeeld 'danger'

  @Output() selecteer = new EventEmitter<persoon>();
  @Output() itempress = new EventEmitter<persoon>(); //longpress
  @Output() itemslide = new EventEmitter<persoon>(); //swipe naar links - als er een slideIcon gegeven is
  @Output('icon-click') iconClick = new EventEmitter<any>();

  @ViewChild('slider') slider: IonItemSliding;

  private slideOverGrens: boolean = false; //true als onSlide detecteert dat er over de grens is geslide. Een mouseup/touchent doet dan het slide-event

  _persoon: persoon; //de vriend, gezet via set persoon

  constructor(injector: Injector) {
    super(injector, "debug");
  }

  /**
   * Zet de persoon: een stdpersoon of een id
   * @param p
   */
  @Input('persoon') set persoon(p: persoon | number) {
    if (typeof (p) === 'number') {
      void this.haalPersoon(p);
    } else {
      this._persoon = p; //
    }
  }

  /**
   * Helper: vraag persoon op
   * @param id
   */
  private async haalPersoon(id: number) {
    try {
      this._persoon = await this.api.persoon(id);
    } catch (_e) {
    }
  }

  /**
   * Return de ava-src
   */
  get ava(): string {
    return (this._persoon && this.api.geturl(this._persoon.ava)) || 'assets/imgs/persoon_default.svg';
  }

  /**
   * Return een string voor het proefmoment
   */
  get proefmoment(): string {
    return (this._persoon && this._persoon.geproefd) ? moment(this._persoon.geproefd.moment, "YYYYMMDD").format('LL') : '';
  }

  get parsedItemclass(): string {
    if (this.itemclass) {
      return this.itemclass; //letterlijk
    }

    if (this.itemclassProp) {
      return this._persoon._meta ? this._persoon._meta[this.itemclassProp] : null;
    }

    return null;
  }

  get parsedEndIcon(): string {
    if (this.endIcon) {
      return this.endIcon;
    }

    if (this.endIconProp) {
      return this._persoon._meta ? this._persoon._meta[this.endIconProp] : null;
    }

    return null;
  }

  /**
   * De select-handler
   * @param bier
   */
  async btnclick() {
    if (this._persoon) {
      if (this.push === 'detail' && this._persoon) {
        await this.naarPersoon(this._persoon);         //toon de details, en emit als de nav klaar is
      }
      this.itemclick.emit(this._persoon);
    }
  }


  /**
   * De ava is niet op te halen (bijv bij FB CDN-problemen). We zetten een default in de plaats
   * @param event
   */
  avaError(event) {
    console.warn(`Error getting ava`, event.target.src);
    try {
      event.target.src = 'assets/imgs/persoon_default.svg';
    } catch (e) {
      console.error(e);
    }
  }
  /**
   * Handle slide. Werkt niet goed met ionSwipe. Dus daarom pakken ionDrag
   * We flaggen hier wanneer men over de grens is. In de touchend handler van het echte ion-item
   * volgt dan eventueel het event (zie onCheckEndSlide)
   * Hierdoor zitten we niet on touchmove-handlers e.d. als het item bijv verwijderd wordt.
   */
  async onSlide() {

    //we moeten verder open sliden dan 1. Want exact 1 is wat er soms gebeurt als je een gesture
    if ((!this.slideIcon) || (!this._persoon) || this.disabled || Math.abs(await this.slider.getSlidingRatio()) <= 1) {
      //slideovergrens is dus niet meer
      this.slideOverGrens = false;
      return; //niet van toepassing
    }

    //het enige dat we hier doen is SlideOverGrens goed zetten
    this.slideOverGrens = true;
  }

  /**
   * Handler van touchend / mouseup op de button zelf. Is dit een sllide-end?
   */
  async onCheckEndSlide() {
    if (this.slideOverGrens) {
      //hoe dan ook: weer dicht
      await this.slider.close();
      //emit het
      this.itemslide.emit(this._persoon);
      this.slideOverGrens = false; //niet meer
    }
  }

  onSwipe() {
    this.log.warn('*** swipe');
  }

  onDragend() {
    this.log.debug(`dragend!!`);
    this.slider.close();
  }
}
