import {Injectable} from '@angular/core';
import {SpinJS2} from 'sihw-ng/spinjs2/spinjs2';
import {Sihwlog, Sihwlogger} from 'sihw-ng/sihwlog/sihwlog';
//import {Facebook, FacebookLoginResponse} from "@ionic-native/facebook";
import {
  Config, getPlatforms,
  LoadingController,
  ToastController
} from "@ionic/angular";
// import {InAppBrowser} from "@ionic-native/in-app-browser";
import {TranslateService} from "@ngx-translate/core";
import * as moment from 'moment';
import {Subject, Subscription} from "rxjs";
import {bier, mainlijst, persoon, userdata} from "../../beerprotypes";
import {Sihwsemaphore} from "sihw-ng/semaphore/sihwsemaphore";

//we hebben hier een apiversion. Die staat los van de officiele appversion in de stores
//het is de api-versie tbv keuzes in beschikbare blokken e.d.
//aanpassen dus

const apiversion = 0.10;

//we bewaren de originele window.open, voordat inappbrowser ermee aan de haal gaat
//nodig voor facebookacties

@Injectable()
export class Api {
  //de beschikbare talen, zie setTaal
  public readonly talen = {nl: true, en: true};
  public readonly standaardtaal = 'nl'; //als er een taal wordt gevraagd die er niet is

  //datumlocale
  public datum: any = {
    picker: '', //pickerformat,
    maanden: [], //maandnamen
  };

  private _besettings: any = null; //door het backend ingestelde settings
  private _userdata: userdata = null; //de userdata vanuit het backend

  //subjects
  //dataNotify emits boodschappen rond wijzigingen in interne data
  //op basis van backendNotify van spinjs of van eigen afleidingen
  //subscribe via onChange
  protected dataNotify = new Subject<{ msg: string, data?: any }>(); //emit bij backend-notify (voor zover mogelijk)

  protected backendSubscription: Subscription;
  private log: Sihwlogger;
  private _postInitPromise: Promise<void>;
  private storage = localStorage || sessionStorage || {};
  private cache = new Map(); //interne memcache voor bepaalde dingen. Zie getCache en setCache

  private readonly cacheDuration = {minutes: 5}; //moment add/subtract object

  private blockcount = 0; //zijn we al geblockt in send
  private blocker: HTMLIonLoadingElement = null; //de blocker zelf
  private loggedInPromise: Promise<any> = null; //niet dubbel vragen


  constructor(
    /*private fb: Facebook,*/
    private translate: TranslateService,
    private loading: LoadingController,
    private toast: ToastController,
    // private inAppBrowser: InAppBrowser,
    private appConfig: Config,
    private SpinJS2: SpinJS2,
    private Sihwlog: Sihwlog,
    private Semaphore: Sihwsemaphore
    ) {
    this.log = this.Sihwlog.logger('debug', 'API');
    this.log.info(`BeerPro Api - Spin in het Web`);

    void this.init();

    this.SpinJS2.onInternal(
      {
        next: async v => {
          this.log.debug(`Spinjs2 event`, v);
          if ((!this._besettings) && v && v.event && v.event === "connect") {
            //haal de _besettings
            try {
              this.log.debug("*** Ophalen besettings");
              this._besettings = await this.send('auth', 'beSettings');
            } catch (e) {
              this.log.error(e);
            }
          }

          if (v.event && v.event === 'reconnect' && this.loggedInSync) {
            //even opnieuw aanmelden voor notificaties
            //als we niet langer goede auth hebben komen we daar wel op een andere manier achter
            this.log.debug(`Opnieuw aanmelden voor notificaties`);
            void this.send('auth', 'connectNotificaties', null, false, false); //async

          }
        }
      });

    this.backendSubscription = this.SpinJS2.onNotify({
      next: e => {
        void this.onBackend(e);
      }
    })
  }


  ///////////////////////////// APP MANAGEMENT //////////////////////////////////

  /**
   * Getter voor userdata. We gaan er maar vanuit dat caller er niet aan sleutelt
   */
  public get userdata(): userdata {
    return this._userdata;
  }

  public get besettings(): any {
    return Object.assign({}, this._besettings || {});
  }

  public get huidigetaal(): string {
    return this.translate.currentLang;
  }


  /**
   * Geeft de postinitpromise terug, zodat splash api.afterInit.then(...) kan doen
   */
  public get afterInit(): Promise<void> {
    return this._postInitPromise;
  }


  /**
   * Waren we ooit ingelogd?
   */
  public get wasLoggedIn(): boolean {
    return !!this.storage['wasLoggedIn'];
  }

  private get deviceKey(): string {
    return this.storage['deviceKey'] || "";
  }

  private set deviceKey(key: string) {
    this.storage['deviceKey'] = key;
  }

  /**
   * Zet een bepaalde taal. Wij gebruiken wel de fallback van translate, maar zetten altijd
   * ook een taal die we ondersteunen. Dat scheelt een mislukte load en bovendien kunnen we dat
   * altijd de echt gebruikte taal opvragen bij huidigetaal
   * @param taalcode
   */
  public async setTaal(taalcode: string): Promise<void> {
    this.log.debug(`Api: setTaal(${taalcode})`);
    if (!this.talen[taalcode]) {
      this.log.warn(`Api: taal ${taalcode} niet beschikbaar. We gebruiken ${this.standaardtaal}.`);
      taalcode = this.standaardtaal;
    }
    this.translate.setDefaultLang(this.standaardtaal); //terugval bij missende vertalingen
    await this.translate.use(taalcode); //bepaalt de te laden json
    this.log.debug(`***TAAL GELADEN`);

    //Standaardtaal in het framework
    this.translate.get(['i.backbuttontext']).subscribe((res: any) => {
      this.appConfig.set("backButtonText", res['i.ios_backbuttontext']);
      this.log.warn(`todo: afschaffen api.appConfig en gewoon [text] in ion-back-button`);
    });

    //en ook moment - globaal, dus dat gaat nu gelden voor nieuwe moment()-objecten
    moment.locale(taalcode);
    this.setDatumlocale();
    this.log.debug("Moment locale geladen. Een uur geleden is: ", moment().subtract(1, 'hour').fromNow());
    this.log.debug("Moment locale maanden", moment.localeData().months());
  }

  /**
   * Zet onze datum-property voor gebruik in datepickers
   */
  private setDatumlocale(): void {
    let l = moment.localeData();
    this.datum.maanden = l.months();
    this.datum.picker = l.longDateFormat('LL'); //het format
  }

  /////////////////////////////// LOGINFLOW ////////////////////////////////////////
  /**
   * Test het backend
   */
  public async ping(): Promise<void> {
    await this.SpinJS2.ping();
  }

  /**
   * Maak een nieuwe native account aan, na allerlei checks
   * @param naam
   * @param email
   * @param password
   * @returns Een object met een succes boolean. Als true dan een gebruikerobject erbij, als fout, dan een foutcode erbij
   */
  public async nativeSignup(naam: string, email: string, password: string): Promise<any> {
    this.logout();
    let data = {
      naam: naam,
      email: email,
      wachtwoord: password
    };

    try {
      let res = await this.send('auth', 'nativeSignup', data, true, false); //geen errortoast, dat doet de caller wel
      //gelukt?
      this.log.debug(`Aanmelden gelukt`, res);
      return {
        success: true,
        data: res.gebruiker
      };
    } catch (err) {
      this.log.warn(err);
      return {
        success: false,
        errcode: err.code || "INTERNAL_ERROR"
      };
    }
  }

  /**
   * Native login. Als dit lukt wordt de userdata gezet. Exception-proof. Returnt een lege string als alles okee, of een foutcode bij problemen!!
   * @param username
   * @param password
   * @param [code] de bevestigingscode uit de mail
   * @returns Een lege string als alles ok! Of een foutcode bij problemen
   */
  public async nativeLogin(username: string, password: string, code?: string): Promise<string> {
    this.logout(); //uitloggen
    let data = {
      login: username,
      wachtwoord: password,
      code: code
    };

    try {

      let res = await this.send('auth', 'nativeLogin', data, true, false); //geen error toast
      //gelukt? -- we krijgen een webtoken en data
      this.log.debug('inloggen gelukt', res);
      this._updateLogindata(res);
      return "";
    } catch (err) {
      this.log.debug('err bij login', err);
      return err.code || "INTERNAL_ERROR";
    }
  }

  public async nieuweCode(username: string) {
    try {
      await this.send('auth', 'nieuwecode', {login: username}, true, false);
    } catch (e) {
      this.log.debug("Fout!");
      return false;
    }
    return true; //verder niets
  }

  /**
   * Regel de hele fblogin. Dit zorgt natuurlijk voor ui-acties, maar dat regelen wij niet dus kan wel hier
   * @param {boolean} [connectAccount = false] Als true, dan wordt er ingelogd bij FB om aan de huidige account te connecten. Er wordt dan dus niet uitgelogd.
   * @returns Een lege string als alles ok! Of een foutcode bij problemen
   */
  public async fbLogin(connectAccount: boolean = false): Promise<string> {
    //TODO: weer erin zetten
    this.log.warn(`TODO: fbLogin`);
    return "NOT_IMPLEMENTED";
    /*
    if (!connectAccount) {
      this.logout();
    }

    //fix inappbrowser door de hook op window.open te verwijderen
    //zie ook app.module.ts
    console.warn(`Voor fix`, window.open);
    const oldopen = window.open;
    window.open = (<any>window).orig_open;
    console.warn(`na fix`, window.open, window.open === oldopen);

    try {
      let fbres: FacebookLoginResponse = await this.fb.login(this._besettings.fbpermissies);
      //herstel inappbrowser
      window.open = oldopen;
      // this.log.debug(`Facebookresponse`, fbres);
           if (fbres.status !== 'connected') {
              return "NO_AUTH";
            }
      //we zijn dus connected, maar we weten niet of we alle permissies hebben, dat regelt het backend

      let res = await this.send(`auth`, connectAccount ? 'fbConnect' : 'fbLogin', {
        accessToken: fbres.authResponse.accessToken
      }, true, false); //geen errortoast
      //gelukt, dan verder als beerpro-account
      this.log.debug(`FB ${connectAccount ? 'verbinden' : 'inloggen'} gelukt`, res);
      this._updateLogindata(res);
      return "";
    } catch (err) {
      //herstel inappbrowser
      window.open = oldopen;
      this.log.debug('err bij fbLogin', err);
      if (err && err.code) {
        switch (err.code) {
          case 'FB_INCOMPLETE':
          //BE heeft weer uitgelogd
          //doorvallen:
          default:
            return err.code;
        }
      } else {
        return "CANCELLED";
      }
    }

     */
  }

  /**
   * Geef een boolean die aangeeft of we ingelogd (lijken te) zijn
   *
   */
  public async loggedIn(): Promise<boolean> {
    if (!
      this.SpinJS2.hasAuth
    ) {
      //gaat hem niet worden
      return false;
    }

    if (this._userdata) {
      //dat ziet er goed genoeg uit
      return true;
    }

    //wel een auth, nog geen data. We zullen het moeten vragen
    let vanons = false;
    try {
      if (!this.loggedInPromise) {
        //zo combineren we meerdere calls
        vanons = true;
        this.loggedInPromise = this.send('auth', 'relogin', null, true, false); //geen errortoast
      } else {
        this.log.debug(`loggedIn()-call gecombineerd met lopende`);
      }
      let res = await this.loggedInPromise; //resultaat
      if (vanons) {
        this.loggedInPromise = null;
      }
      this.log.debug(`loggedIn backend check geslaagd`, res);
      this._updateLogindata(res);
      return true;
    } catch (e) {
      if (vanons) {
        this.loggedInPromise = null; //weg ermee
      }
      this.log.debug(e);
      this.logout();
      return false;
    }
  }

  /**
   * Geef terug of we nu zijn ingelogd, zonder dit aan het be te vragen
   */
  public get loggedInSync(): boolean {
    return this.SpinJS2.hasAuth && (!!this._userdata);
  }

  /**
   * Clear alle data
   */
  public logout() {
    this.SpinJS2.authKey = null;
    this._userdata = null;
    //TODO: emit?
    this.cache.clear(); //leeg
  }

  //////////////////////////// DIRECTE WRAPS //////////////////////////////////////
  /**
   * Haal de data voor de maintimeline op.
   */
  public async maindata(alleenUitnodigingen: boolean = false): Promise<mainlijst> {
    //dit cachen we nooit
    try {
      return await this.send(`main`, 'tl', {}, true, true);
    } catch (e) {
      this.log.debug(e);
      return null; //niets dus
    }
  }

  /**
   * Haal de bierdata
   * @param force Als gegeven en true wordt er niet op de cache gelet (pull-reload etc)
   */
  public async bierlijst(force: boolean = false): Promise<any[]> {
    //we cachen nogal ingewikkeld. Bierlijst en bierdata apart
    if (force) {
      this.deleteCache('bierlijst');
      this.deleteCache('bierdata');
    }
    try {
      let ids = this.getCache('bierlijst', 'std');
      if (!ids) {
        let res = await this.send('bier', 'lijst');
        ids = [];
        for (let bier of res.res) {
          ids.push(bier.id);
          this.setCache('bierdata', bier.id, bier); //apart in de cache
        }
      }
      //nu hebben we de ids uit de cache of backend
      //als er ook data uit het backend kwam, dan zit dat in de cache, dus dat gaat snel genoeg
      let lijst = [];
      for (let id of ids) {
        lijst.push(await this.bierdata(id));
      }
      this.setCache('bierlijst', 'std', ids); //hier alleen de ids
      return lijst
    } catch (e) {
      this.log.error(e);
      return []; //dan maar
    }
  }

  /**
   * Lever standaard bierdata voor een bepaalde bierid (beerpro-bier)
   * @param bierid
   * @param background
   * We locken deze call om teveel parallel te voorkomen
   */
  public async bierdata(bierid: number | string, background: boolean = false): Promise<any> {
    //direct locken, anders gaan we nog steeds elke keer naar achteren
    // let lock = await this.getlock(`bierdata${bierid}`); //1 tegelijk, per biertje
    await this.Semaphore.waitLock('api.bierdata');
    if (typeof bierid !== 'number')
    {
      bierid = parseInt(bierid,10);
    }
    let cache = this.getCache('bierdata', bierid);
    if (cache) {
      this.Semaphore.unlock('api.bierdata');
      return cache;
    }
    try {

      let res = await this.send('bier', 'data', {id: bierid}, !background);
      this.setCache('bierdata', bierid, res);
      this.Semaphore.unlock('api.bierdata');
      return res;
    } catch (e) {
      this.log.error(e);
      this.Semaphore.unlock('api.bierdata');
      return false; //dan maar
    }
  }

  /**
   * Haal een lijst met populair bier op, gegeven de ingelogde gebruiker
   */
  public async populairBier(): Promise<any[]> {
    let cache = this.getCache('populairBier', 'std');
    if (cache) {
      return cache;
    }
    try {
      let res = await this.send('bier', 'populair');
      this.setCache('populairBier', 'std', res.res);
      return res.res;
    } catch (e) {
      this.log.error(e);
      return []; //dan maar
    }
  }

  /**
   * Haal een lijst met bier die op een bepaalde lijst staat op, gegeven de gebruiker
   * @param lijst
   */
  public async bierOpLijst(lijst: ('fav' | 'wish' | 'bestel')) {
    let cache = this.getCache('bierOpLijst', lijst);
    if (cache) {
      return cache;
    }
    try {
      let res = await this.send('bier', 'oplijst', {lijst: lijst});
      this.setCache('bierOpLijst', lijst, res.res);
      return res.res;
    } catch (e) {
      this.log.error(e);
      return []; //dan maar
    }
  }

  /**
   * Haal proefgeschiedenis metadata van dit biertje uit het backend
   * @param bierid
   * @param uitgebreid: ook oudere proevingen
   */
  public async geschiedenis(bierid, uitgebreid: boolean): Promise<any> {
    let cachekey = uitgebreid ? 'u' : 'c';
    let cache = this.getCache('geschiedenis', bierid);

    if (cache[cachekey]) {
      return cache[cachekey];
    }
    try {
      let res = await this.send('bier', 'geschiedenis', {
        id: bierid,
        uitgebreid: uitgebreid
      });
      cache = cache || {};
      cache[cachekey] = res;
      this.setCache('geschiedenis', bierid, cache); //uitgebreid en compact in dezelfde cache
      return res;
    } catch (e) {
      this.log.error(e);
      return false; //dan maar
    }
  }

  /**
   * Toggle een biertje op een lijst van de huidige user. Als resultaat volgt de bijgewerkte bierdata
   */
  public async toggleLijst(bierid, lijst): Promise<any> {
    try {
      let res = await this.send('bier', 'togglelijst', {
        id: bierid,
        lijst: lijst
      }, false);
      this.setCache('bierdata', bierid, res); //is weer de bierdata
      this.deleteCache('bierOpLijst', lijst); //die klopt nu niet meer
      //de bierlijst hoeft niet opnieuw, want die heeft in de cache alleen ids. Even opnieuw opbouwen en klaar
      this.log.debug(`Notify bierGewijzigd (toggleLijst)`);
      this.dataNotify.next({msg: 'bierGewijzigd', data: bierid});
      return res;
    } catch (e) {
      this.log.error(e);
      this.deleteCache('bierdata', bierid); //niet meer zeker
      this.deleteCache('bierOpLijst', lijst); //die klopt nu niet meer
      return false; //dan maar
    }
  }


  /**
   * Zoek op bier. Geeft in het resultaat de gezochte term en de biertjes terug
   * @param zoekterm
   * @param uitgebreid
   */
  public async bierZoeker(zoekterm: string, uitgebreid = false): Promise<any> {
    try {
      let res = await this.send('bier', 'zoek', {
        zoekterm: zoekterm,
        uitgebreid: uitgebreid
      });
      return {
        zoekterm: zoekterm, //zodat caller kan checken of deze nog valide is
        biertjes: res.res //kan leeg zijn
      }
    } catch (e) {
      this.log.error(e);
      return [];
    }
  }

  /**
   * Verwijder een bierproef
   * @param proefdata: hele proefdata uit backend
   */
  public async verwijderProefdata(proefdata: any) {
    try {
      await this.send('bier', 'verwijderproef', {
        id: proefdata.id
      });
      this.updateProefcache(proefdata.bier_id, proefdata.id);
      return true; //gelukt
    } catch (e) {
      this.log.error(e);
      return false;
    }
  }

  /**
   * Sla het proeven van een biertje op in het backend. Returnt true als dit lukt, false bij fout
   * Hele rits parameters, zie backend
   * data.bestaand is de id van een te wijzigen proef. Als null, dan is het een nieuwe
   */
  public async bierGeproefd(data: any): Promise<boolean> {

    //bier is een stdbier object van ons, met in elk geval een bron en een id
    //locatie is een locatieobject van ons. Bevat een bp_id of een google_id, naam, adres, positie (lat,lng)
    try {
      await this.send('bier', 'geproefd', data);
      this.updateProefcache(data.bier && data.bier._bron === 'bp' && data.bier.id, data.bestaand);
      return true; //gelukt
    } catch (e) {
      this.log.error(e);
      return false;
    }
  }

  /**
   * Update de cache na een wijziging in proefinfo. Eventueel ook voor een specifiek biertje. En notify
   * @param [bierid]
   * @param [proefid]
   */
  private updateProefcache(bierid: number = null, proefid: number = null): void {
    this.deleteCache('bierlijst'); //niet meer geldig
    this.deleteCache('mijnlocaties');
    this.deleteCache('bierLocaties');
    this.log.debug(`Notify bierlijstGewijzigd`);
    this.dataNotify.next({msg: 'bierlijstGewijzigd'});
    if (bierid) {
      this.deleteCache('bierdata', bierid);
      this.deleteCache('geschiedenis', bierid);
      this.log.debug(`Notify bierGewijzigd`, bierid);
      this.dataNotify.next({msg: 'bierGewijzigd', data: bierid});
    }
    if (proefid) {
      this.deleteCache('proefdata', proefid);
    }
  }

  /**
   * Haal details van een bepaalde proefing uit het backend. Geeft proefform, proefmeta en proefdata terug, geen bierdata(ivm caching)
   * Het backend checkt toestemming: van user zelf of vriend
   * @param proefid
   */
  public async proefdata(proefid: number): Promise<any> {
    try {
      let res = this.getCache('proefdata', proefid);
      if (!(res)) {
        res = await this.send('bier', 'proefdata', {id: proefid});
        res = res.res;
        this.setCache('proefdata', proefid, res);
      }
      //en nu nog uitpakken
      if (res.data) {
        res.data = JSON.parse(res.data);
      }
      return res;
    } catch (e) {
      this.log.error(e);
      return false; //dan maar
    }
  }


  //proefformulieren

  /**
   * Geef een array met proefform-ident + proefformnaam in de huidige taal
   */
  public async proefforms(): Promise<any[]> {
    let data = await this.proefformsdata();
    let forms = Object.keys(data).map(pfkey => {
      let pf = data[pfkey];
      return {
        ident: pf.ident,
        _s: pf.volgnummer,
        naam: pf[`naam_${this.huidigetaal}`] || pf[`naam_${this.standaardtaal}`] || pf.ident
      }
    });
    forms.sort((pf1, pf2) => {
      if (pf1._s < pf2._s) {
        return -1;
      } else if (pf1._s > pf2._s) {
        return 1;
      } else {
        return pf1.naam < pf2.naam ? -1 : 1;
      }
    });
    return forms;
  }

  /**
   * Return de proefformdata met de opgegeven ident
   * @param ident
   */
  public async proefform(ident: string): Promise<any> {
    let data = await this.proefformsdata();
    let form = data[ident];
    if (form) {
      form.blokken.sort((b1, b2) => b1.volgnummer > b2.volgnummer ? 1 : -1);
    }
    return form || null;
  }

  ////////////////// Locaties //////////////////////


  /**
   * Haal de locaties voor een kwadrant cached op. Returnt array/hash
   * @param kwadranten Kwadrantcodes (zie SihwLocatie)
   * @param [perkwadrant]: als gegeven, dan wordt er geen array maar een hash met arrays teruggegeven
   */
  async haalKwadrantLocaties(kwadranten: string | string[], perkwadrant: boolean = false): Promise<any[] | any> {
    let locaties = {};
    let ophalen = {};

    if (!Array.isArray(kwadranten)) {
      kwadranten = [kwadranten];
    }
    try {
      //welke locaties hebben we al?
      for (let kwadrant of kwadranten) {
        let kwlocaties;
        if ((kwlocaties = this.getCache('locaties', kwadrant))) {
          //deze hebben we al
          locaties[kwadrant] = kwlocaties;
        } else {
          ophalen[kwadrant] = []; //straks vullen
        }
      }
      //okee, ophalen
      if (Object.keys(ophalen).length) {
        let res = await this.send("locatie", "haal", {
          kwadrant: Object.keys(ophalen)
        });
        //cachen per kwadrant
        if (res.locaties) {
          for (let loc of res.locaties) {
            if (ophalen[loc.kwadrant]) { //test op locaties buiten gevraagde kwadranten
              ophalen[loc.kwadrant].push(loc);
            }
          }
          for (let kwadrant of Object.keys(ophalen)) {
            this.setCache('locaties', kwadrant, ophalen[kwadrant]);
            locaties[kwadrant] = ophalen[kwadrant];
          }
        }
      }
    } catch (e) {
      this.log.error(e);
      return perkwadrant ? {} : [];
    }
    if (perkwadrant) {
      return locaties;
    } else {
      let plat = [];
      for (let kwadrant of Object.keys(locaties)) {
        plat.push(...locaties[kwadrant]);
      }
      return plat;
    }
  }

  async mijnLocaties(): Promise<any> {
    let cache = this.getCache('mijnLocaties', 'std');
    if (cache) {
      return cache;
    }
    try {
      let res = await this.send('locatie', 'mijn');
      this.setCache('mijnLocaties', 'std', res.locaties);
      return res.locaties;
    } catch (e) {
      this.log.error(e);
      return false;
    }
  }

  /**
   * Lever voor het opgegeven kwadrant een lijst met bp-locatie-id's die een gegeven biertje schenken. We doen dit gewoon per kwadrant, omdat dat een stuk simpeler werkt
   * @param kwadrant
   * @param bierid
   */
  async bierLocaties(kwadrant: string, bierid: number): Promise<number[]> {
    let cache = this.getCache(`bierLocaties`, `${kwadrant}_${bierid}`);
    if (cache) {
      return cache;
    }
    try {
      let res = await this.send(`locatie`, 'bier', {
        id: bierid,
        kwadrant: kwadrant
      });
      this.setCache('bierLocaties', `${kwadrant}_${bierid}`, res.locaties);
      return res.locaties;
    } catch (e) {
      this.log.error(e);
      return []; //dan maar
    }
  }


  //vrienden

  /**
   * Haal de vriendenlijst
   * @param force
   */
  public async vriendenlijst(force: boolean = false): Promise<any[]> {
    if (force) {
      this.deleteCache('vriendenlijst');
    }
    try {
      let vrienden = this.getCache(`vriendenlijst`, 'std');
      if (vrienden) {
        return vrienden;
      }
      let res = await this.send('gebruiker', 'vrienden');
      this.setCache('vriendenlijst', 'std', res.vrienden);
      return res.vrienden;
    } catch (e) {
      this.log.error(e);
      return []; //dan maar
    }
  }

  /**
   * Geef de std-data van een persoon terug
   * @param id
   * @param force
   * @param background
   */
  public async persoon(id: number, force: boolean = false, background: boolean = false): Promise<any> {
    try {
      await this.Semaphore.waitLock('api.persoondata'); //1 tegelijk, over alle personen
      id = +id; //nummer
      if (force) {
        this.deleteCache('persoondetails', id);
        this.deleteCache('persoon', id); //ook
      }

      let persoon = this.getCache(`persoon`, id);
      if (!persoon) {
        persoon = await this.send('gebruiker', 'persoon', {
          id: id
        }, !background);
        //kortenaam
        let m;
        if ((m = persoon.naam.match(/^(\S+)/))) {
          persoon.kortenaam = m[1];
        } else {
          persoon.kortenaam = persoon.naam;
        }
        this.setCache(`persoon`, id, persoon);
      }
      this.Semaphore.unlock('api.persoondata');
      return persoon;
    } catch (e) {
      this.log.error(e);
        this.Semaphore.unlock('api.persoondata');
      return null;
    }
  }

  /**
   * Geef persoonsdetails terug, voor zover het backend dat wil
   * Akey is verplicht, tegen leegzuigen
   */
  public async persoondetails(id: number, akey: string, force: boolean = false): Promise<any> {
    try {
      id = +id; //nummer

      if (force) {
        this.deleteCache('persoondetails', id);
        this.deleteCache('persoon', id); //ook
      }
      let details = this.getCache(`persoondetails`, id);
      if (!details) {
        details = await this.send('gebruiker', 'detail', {
          id: id,
          akey: akey
        });
        this.setCache(`persoondetails`, id, details);
      }
      return details;
    } catch (e) {
      this.log.error(e);
      return null;
    }
  }

  /**
   * Vraag lijst met std-gebruikers die de user hebben uitgenodgd, en die niet verborgen zijn. Gecached
   */
  public async uitnodigingen(): Promise<any[]> {
    try {
      let uitnodigingen = this.getCache(`uitnodigingen`, 'std');
      if (uitnodigingen) {
        return uitnodigingen;
      }
      let res = await this.send(`gebruiker`, `uitnodigingen`);
      this.setCache(`uitnodigingen`, 'std', res.res);
      return res.res;
    } catch (e) {
      this.log.error(e);
      return []; //dan maar
    }
  }

  /**
   * Haal de social vrienden op voor weergave. Niet gecached
   */
  public async socials(unconnected: boolean = false): Promise<any[]> {
    try {
      let socials: any = await this.send('gebruiker', 'socialconnected', {unconnected: unconnected});
      return socials.res;
    } catch (e) {
      this.log.error(e);
      return []; //dan maar
    }
  }

  /**
   * Bevriend een uid. Het BE maakt het een uitnodiging als er nog geen verzoek de andere kant op is. Anders een bevriending.
   * @param ander
   */
  public async bevriend(ander: number): Promise<void> {
    //het lukt altijd, of anders reject
    return this.send('gebruiker', 'bevriend', {ander: ander});
  }

  /**
   * Trek een uitnodiging weer in
   * @param ander
   */
  public async uitnodigingIntrekken(ander: number): Promise<void> {
    return this.send('gebruiker', 'uitnodigingintrekken', {ander: ander});
  }

  /**
   * Stuur een uitnodiging naar een e-mailadres van een niet-lid
   * @param ander
   */
  public async uitnodigEmail(ander: string): Promise<void> {
    //het lukt altijd, of anders reject
    return this.send('gebruiker', 'uitnodigemail', {ander: ander});
  }

  /**
   * Ontvriend een uid. Dat lukt op zich altijd, tenzij een reject op errorniveau
   */
  public async ontvriend(ander: number, akey: string): Promise<void> {
    return this.send('gebruiker', 'ontvriend', {ander: ander, akey: akey});
  }

  /**
   * Verberg de uitnodiging van een gebruiker.
   * @param gebruiker
   */
  public async verberguitnodiging(gebruiker): Promise<void> {
    //het lukt altijd, of anders reject
    return this.send('gebruiker', 'verberguitnodiging', {ander: gebruiker});
  }


  /**
   * Zoek gebruikers op profilenaam of e-mailadres
   * @param zoekhandle
   * @param unconnected Alleen niet-vrienden?
   * @param beerpro Alleen beerpro-gebruikers? (Anders kan er bij een volledig e-mailadres een "onbekendEmail" teruggegeven worden)
   */
  public async zoekGebruiker(zoekhandle: string, unconnected: boolean = false, beerpro: boolean = false): Promise<any> {
    this.log.debug(`zoekGebruiker ${zoekhandle}`);
    try {
      let res = await this.send('gebruiker', 'zoek', {
        zoekterm: zoekhandle,
        unconnected: unconnected
      }, false); //geen block, want gewoon tijdens tikken

      if (beerpro && res.onbekendEmail) {
        //de zoekstring is een geldig e-mailadres dat niet is aangetroffen, en we willen alleen beerprogebruikers
        return {
          zoekterm: zoekhandle, //valid-check
          gebruikers: []
        }
      } else {
        return {
          zoekterm: zoekhandle, //zodat caller kan checken of deze nog valide is
          gebruikers: res.res, //kan leeg zijn
          onbekendEmail: !!res.onbekendEmail //is true als zoekterm een geldig e-mailadres is, dat niet aangetroffen is
        }
      }
    } catch (e) {
      this.log.error(e);
      return [];
    }
  }

  ///////////////////////////////// PROEVERIJ ///////////////////////////
  /**
   * Haal alle relevante proeverijen op, voor de proeverijenpagina.
   * @param force
   */
  public async proeverijen(force: boolean = false): Promise<any> {
    //TODO: caching
    try {
      let r = (await this.send('proeverij', 'proeverijen'));
      this.log.debug("Terug van proeverijen", r);
      return r.res;
    } catch (e) {
      this.log.error(e);
      return []; //dan maar
    }
  }

  /**
   * Geef de info van 1 proeverij terug, voor een proeverijblok
   * @param id
   * @param force
   * Returnt een proeverij of null als het niet mag of kan
   */
  public async proeverijInfo(id: Number, force: boolean = false): Promise<any> {
    //todo; caching
    try {
      return await this.send('proeverij', 'proeverijInfo', {id: id});
    } catch (e) {
      this.log.error(e);
      return null; //dan maar
    }

  }

  /**
   * Haal de proeverijdata op
   * @param id
   * @param foredit Als true, dan zal het falen als de user geen editrechten op de proeverij heeft (geen eigenaar)
   */
  public async proeverijdata(id: number, foredit: boolean = false): Promise<any> {
    try {
      let res = (await this.send('proeverij', 'data', {id: id, foredit: foredit}));
      if (res && res.data) {
        //paar dingen aanpassen
        res.data._moment = res.data.moment ? moment(res.data.moment, "YYYYMMDDHHmm") : null;
        res.data.moment = res.data.moment ? res.data._moment.format("YYYY-MM-DDTHH:mm:00.000") : null;
        res.data.locatie = res.data.locatie || "-"; //- betekent in fe: expliciet niet ingevuld
        res.data.deelnemers = await Promise.all((res.data.deelnemers || []).map(async d => {
          return {persoon: await this.persoon(d.id), status: d.status};
        }));
        res.data.biertjes = await Promise.all((res.data.biertjes || []).map(async bid => {
          return this.bierdata(bid);
        }));
      }
      this.log.debug(`Return proeverijdata`, res);
      return res;
    } catch (e) {
      this.log.error(e);
      return false; //dan maar
    }
  }

  /**
   * Sla een proeverij op
   * Id is het id van een bestaande proeverij, bij null is het een nieuwe.
   * Backend controleert wel. Returnt true als dit lukt, anders false
   */
  public async opslaanProeverij(id: number, proefdata: any, deelnemers: persoon[], biertjes: bier[]): Promise<boolean> {
    //we werken de proefdata uit
    //dat moment uit de proefdata moet tijdzonevrij-blijven, dus we zetten hem anders om, want de ion-datetime maakt er utc van
    let momentstr;
    if (proefdata.moment) {
      let m = /^(....)-(..)-(..)T(..):(..)/.exec(proefdata.moment);
      if (m) {
        momentstr = m.slice(1, 6).join('');
      }
    }
    let data: any = {
      naam: proefdata.naam,
      beschrijving: proefdata.beschrijving,
      url: proefdata.url,
      urlbeschrijving: proefdata.urlbeschrijving,
      moment: momentstr,
      locatie: proefdata.locatie === "-" ? null : proefdata.locatie, //gewoon as is, nieuw of bestaand. - in frontend is null in backend
      proefform: proefdata.proefform, //op tag, ivm versies,
      deelnemers: deelnemers.map(p => p.id), //kan net zo goed alleen de ids, want die zijn er altijd
      biertjes: biertjes //hier het hele object, want het kan een nieuw biertje zijn met een vreemde bron
    };
    if (id) {
      data.id = id;
    }
    this.log.debug(data);
    try {
      await this.send('proeverij', 'opslaan', data);
    } catch (e) {
      this.log.error(e);
      return false;
    }
    return true;
  }

  /**
   * Verwijder een proeverij. Ux moet de boel gecheckt hebben
   * @param id
   */
  async verwijderProeverij(id: number): Promise<boolean> {
    try {
      await this.send('proeverij', 'verwijder', {
        id: id
      });
      //TODO: caching?
      return true;
    } catch (e) {
      this.log.error(e);
      return false;
    }
  }

  /**
   * Geef van de huidige user aan dat hijzij wel of niet naar een proeverij komt. UX moet de boel gecheckt hebben. BE doet dat ook. Geeft de nieuwe deelnemerstatus terug, of null bij fout
   * @param id proeverij
   * @param komt true = komen, false = weigeren
   */
  async accepteerProeverij(id: number, komt: boolean): Promise<string> {
    try {
      let res = await this.send('proeverij', 'accepteer', {id: id, komt: komt});
      //todo: caching?
      return (res && res.status) || null;
    } catch (e) {
      this.log.error(e);
      return null;
    }
  }

  /**
   * Begin de proeverij, als dat mag, en geef de checkin-code terug
   * @param id
   */
  async beginProeverij(id: number): Promise<string> {
    try {
      let res = await this.send('proeverij', 'begin', {id: id});
      return (res && res.code) || null;
    } catch (e) {
      this.log.error(e);
      return null;
    }
  }

  async sluitProeverij(id: number): Promise<void> {
    try {
      await this.send('proeverij', 'sluit', {id: id});
    } catch (e) {
      this.log.error(e);
    }
  }

  async meldProeverijAanwezig(id: number, code: string): Promise<boolean> {
    try {
      return (await this.send('proeverij', 'aanwezig', {id: id, code: code})).result; //true of false
    } catch (e) {
      this.log.error(e);
      return false;
    }
  }


  /////////////////////////// CMS /////////////////////////////////
  /**
   * Vraag de content van een cms-page aan het backend, of haal het uit de cache
   * @param page
   */
  async cms(page: string): Promise<{ pageTitle: string, html: string }> {
    try {
      let content = this.getCache("cms", page);
      if (!content) {
        content = await this.send(`main`, 'cms', {page: page});
        this.setCache("cms", page, content);
      }
      return content;
    } catch (e) {
      this.log.error(e);
      return null;
    }
  }

  ////////////////////////// HELPERS ////////////////////////////////////

  /*api.geturl(url): parse de gegeven url. Als dit speciale api-'urls' zijn, dan bouwen we het om
   In alle andere gevallen blijven we er vanaf. Daarom any
   speciaal is:
   API:pub:<controller>:<actie>:<arg>[:<arg>]...
   API:priv:<controller>:<actie>:<arg>[:<arg>]...
  */
  public geturl(url: any): any {
    if (url && (typeof (url) == "string")) {
      let m;
      if ((m = url.match(/^API:(pub|priv):(.+?):(.+?)(:(.+))?$/))) {
        return this.SpinJS2.maakGetUrl(m[2], m[3], m[5] ? m[5].split(':') : [], m[1] === 'priv');
      }
    }
    return url; //anders
  }

  /**
   * Open een url in de systeembrowser
   * @param url
   */
  public openBrowser(url: string) {
    this.log.warn(`TODO: open url in browser in PWA`);
    this.log.debug(`Open url in browser ${url}`);
    // this.inAppBrowser.create(url, '_system');
  }

  ///////////////////////// NOTIFICATIES //////////////////////////////////
  /**
   * Subscribe op onze datachanges
   * @param observer
   */
  public onChange(observer: any): Subscription {
    return this.dataNotify.subscribe(observer);
  }

  /////////////////////////////////////// INTERN //////////////////////////

  /**
   * Init-call vanuit constructor.
   */
  private async init() {
    //init SpinJS:
    return this._postInitPromise = new Promise(async (resolve) => {
      await this.setTaal(this.translate.getBrowserLang());   //zet de taal op basis van de browser, wacht op resultaat
      this.log.debug(`Init Api-connectie`);
      this.SpinJS2.whenConnected().then(async () => {
        //hebben we een devicekey? Zo niet, dan gaan we connecten
        //dat doen we ook als de apiversie gewijzigd is
        if (!(this.deviceKey && (this.storage['apiversion'] == apiversion))) {
          //this.device zijn allemaal getters. Dus moet het wat omslachtig
          //object.assign werkt niet
          let devinfo = {
            model: "web",
            version: (<any>navigator).oscpu || "?",
            platform: navigator.userAgent || "?",
            platforms: getPlatforms(),
            uuid: "?",
            manufacturer: "?",
            isVirtual: true,
            serial: "?"
          };

          let connectdata = await this.send('auth', 'initDevice', {
            apiversion: apiversion,
            devinfo: devinfo,
            vervangt: this.deviceKey //als dit een nieuwe versie is zien we dat aan deze
          });
          this.deviceKey = connectdata.device;
          this.storage['apiversion'] = apiversion; //bewaren
        } else {
          this.log.debug(`We hebben al een devicekey`, this.deviceKey);
        }
        //TODO: herregistreer device (los van authkeys)
        //check direct of we zijn ingelogd
        this.log.debug(`Spinjs is connected, nu kijken of we ingelogd zijn`);
        void await this.loggedIn();

        this.log.debug(`Klaar met check, we zijn klaar met init`);

        resolve();
      }).catch(err => {
        this.log.error('Fout');
        this.log.error(err);
      });
    });
  }

  /**
   * Sla de teruggekomen logindata
   * @param data
   * @private
   */
  private _updateLogindata(data: { authkey: string, userdata: userdata }) {
    this.SpinJS2.authKey = data.authkey; //altijd een nieuwe
    this._userdata = data.userdata; //hopla
    //en we zijn dus ingelogd, dus ooit ingelogd geweest
    this.storage['wasLoggedIn'] = true;
    void this.ping();

    //taal?
    if (this._userdata.taal && this._userdata.taal !== this.huidigetaal) {
      this.setTaal(this._userdata.taal);
    }

    //todo: userdata wijziging emitten?
  }

  /**
   * Haal de proefformdata op als dat nodig is
   */
  private async proefformsdata(): Promise<{}> {
    let cached = this.getCache('proefforms', 'std');
    if (cached) {
      return cached;
    }
    //anders ophalen
    try {
      let res = await this.send('proefform', 'forms');
      this.setCache('proefforms', 'std', res.res);
      return res.res;
    } catch (e) {
      this.log.error(e);
      return {};
    }
  }


  /**
   * Interne verzender. Voegt devicekey toe en vangt SPINJS_INVALID_TOKEN op bij verlopen auth
   * @param controller
   * @param action
   * @param [data]
   * @param [block]
   * @param [errortoast]
   */
  private async send(controller: string, action: string, data: any = {}, block: boolean = true, errortoast: boolean = true): Promise<any> {

    data = data || {};
    //specials die we altijd meesturen tenzij overruled
    let specials = {
      _d: this.deviceKey,
      _t: this.huidigetaal,
      _v: apiversion
    };
    for (let special of Object.keys(specials)) {
      if (!(special in data)) {
        data[special] = specials[special]
      }
    }

    try {
      if (block) {
        await this.block();
      }

      let res = await this.SpinJS2.send(controller, action, data, false);  //als het goed gaat, gewoon teruggeven
      if (block) {
        setTimeout(async () => {
          await this.unblock()
        }); //heel even wachten. Als er direct een volgende achteraan komt qua loop, dan laten we de blokker even staan
      }
      return res;
    } catch (err) {
      if (block) {
        await this.unblock();
      }
      if (err && err.code) {
        let doetoast = false;
        if (err.code === "SPINJS_INVALID_TOKEN") {
          //we gaan uitloggen
          this.logout();
          window.location.reload(); //keiharde reload
        } else if (err.code === "FB_UNCONNECTED") {
          this.log.warn(`FB Unconnected`);
          delete this._userdata.credential_types.facebook;
        } else if (err.code === "OLD_VERSION") {
          //altijd toast
          this.logout();
          doetoast = true; //forceer

        } else if (errortoast) {
          doetoast = true;
        }
        if (doetoast) {
          let transkey = `int.be_error.${err.code}`;
          let msg = this.translate.instant(transkey);
          if (msg !== transkey) {
            //het is vertaald
            this.log.warn(msg);
            const toast = await this.toast.create({
              message: msg,
              duration: 10000
            });
            void toast.present(); //niet wachten
          }
        }
        //rethrow sowieso
        throw(err);
      }
    }
  }

  /**
   * Verhoog onze blocker, of begin met blokkeren
   */
  private async block() {
    await this.Semaphore.waitLock('api.block');
    if (this.blockcount <= 0) {
      //we maken een blocker
      if (this.blocker) {
        await this.blocker.dismiss();
      }
      this.blockcount = 0;
      this.log.debug(`create blocker`);
      this.blocker = await this.loading.create({
        cssClass: 'apiblocker',
        spinner: 'bubbles'
      });
      this.log.debug(`Present blocker`);
      try {
        await this.blocker.present();
      } catch (_e) {
      }
      this.log.debug('blocker presented');
    }
    else {
      this.log.debug(`Blocker is er al, count opgehoogd naar ${this.blockcount + 1}`);
    }
    this.blockcount++; //geblockt
    this.Semaphore.unlock('api.block');
  }

  /**
   * Verlaag de blockcount en verwijder eventueel de blocker
   */
  private async unblock() {
    await this.Semaphore.waitLock('api.unblock');
    this.blockcount--;
    if (this.blockcount <= 0) {
      //we maken een blocker
      if (this.blocker) {
        //dit gaat mis als de blocker net gemaakt is, altijd een timeout
        let dismissing = this.blocker; //prop gaat naar null
        this.log.debug(`inplannen dismiss blocker`);
        setTimeout(async () => {
          try {
            this.log.debug(`Dismiss blocker`);
            await dismissing.dismiss();
            this.log.debug(`dismissed`);
          } catch (_e) {
            this.log.error(_e);
          }
        }, 500);
        this.blocker = null;
        this.blockcount = 0;
      }
    }
    else {
      this.log.debug(`Unblock 1 level. Blockcount nu ${this.blockcount}`);
    }
    this.Semaphore.unlock('api.unblock');
  }

  ////backend notify
  async onBackend(e) {
    this.log.debug(`onBackend`, e);
    switch (e.msg) {
      case 'locatiegewijzigd':
        //kwadrant uit cache
        this.log.debug(`Verwijder cache van ${e.data.kwadrant}`);
        this.deleteCache('locaties', e.data.kwadrant);
        break;
      case 'refresh':
        //verzoek van backend om bepaalde data te refreshen. Dat sturen we door en handelen we evt nog hier af
        for (let key of e.data) {
          switch (key) {
            case 'uitnodigingen':
              this.deleteCache('uitnodigingen');
              this.deleteCache('persoondetails');
              this.deleteCache('persoon');
              break;
            case 'vrienden':
              this.deleteCache('vriendenlijst');
              this.deleteCache('persoondetails');
              this.deleteCache('persoon');
              break;
          }
          this.log.debug(`dataNotify`, {msg: 'refresh', data: key});
          this.dataNotify.next({msg: 'refresh', data: key});
        }
        break;
      case 'proeverij':
        //meestal gewoon doorgeven, maar wij regelen de toast bij begin en uitnodiing
        if (e.data.wijziging === "begin" && e.data.mijnstatus !== "uitgenodigd") {
          const toast = await this.toast.create({
            message: this.translate.instant("int.proeverijbegonnen", e.data),
            duration: 10000
          });
          await toast.present();
        }
        if (e.data.wijziging === "uitgenodigd") {
          const toast = await this.toast.create({
            message: this.translate.instant("int.proeverijuitgenodigd", e.data),
            duration: 10000
          });
          await toast.present();
        }
        //en doorgeven
        this.dataNotify.next(e);
        break;
      default:
        //doorgeven maar
        this.dataNotify.next(e);
    }
  }

  //// cache. Is vooral bedoeld voor resultaten van het backend.
  /// we slaan het daarom op als json, zodat we altijd shallow copies hebben
  /**
   * Check in onze memcache op de gegeven sleutel, en of deze nog vers is
   * d is een ding waarvan we een shallow copy maken
   * @param key
   * @param sub subkey, verplicht. Any.
   */
  private getCache(key: string, sub: any): any | false {
    let cached = this.cache.has(key) && this.cache.get(key).get(sub);
    if (cached) {
      //we accepteren hem als hij nog vers is, of als er geen verbinding is
      if (cached.m.isAfter(moment().subtract(this.cacheDuration) || (!this.SpinJS2.connected))) {
        //nog vers. Lever een shallow copy
        try {
          return JSON.parse(cached.d)
        } catch (e) {
          this.log.error(e);
        }
      }
      //weg ermee, het is niet meer vers
      this.cache.get(key).delete(sub);
    }
    return false;
  }

  private setCache(key: string, sub: any, data: any): void {
    try {
      let submap = this.cache.get(key);
      if (!submap) {
        submap = new Map();
        this.cache.set(key, submap);
      }
      submap.set(sub, {
        m: moment(),
        d: JSON.stringify(data)
      });
    } catch (_e) {
    }
  }

  /**
   * Verwijder een cache, met één subkey of alles
   * @param key
   * @param [sub] Als niet gegeven, dan wordt de hele cache voor de hoofdkey verwijderd
   */
  private deleteCache(key: string, sub?: any): void {
    this.log.debug(`Delete cache`, key, sub);
    if (sub) {
      let submap = this.cache.get(key);
      if (submap) {
        submap.delete(sub);
      }
    } else {
      this.cache.delete(key);
    }
  }

}
