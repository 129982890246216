/*
* biergeproefd.ts
* 13-1-2020 - Jelmer Jellema - Spin in het Web B.V.
*
* Directive voor het afbeelden dat iemand een biertje heeft geproefd
* in de main timeline, dus alleen met proefdata
*
* <mainbiergeproefd item=<mainitem_geproefd> (naarPersoon)=<ganaarpersoon($persoon)>>
*/

import {BeerproComponent} from "../../../abstracts/beerprocomponent";
import {bier, mainitem_geproefd, persoon} from "../../../beerprotypes";
import {Component, EventEmitter, Injector, Input, Output} from "@angular/core";

@Component({
  selector: 'mainbiergeproefd',
  templateUrl: './mainbiergeproefd.html',
  styleUrls: ['alle_items.scss','mainbiergeproefd.scss']
})
export class MaingeproefdComponent extends BeerproComponent {
  proefitem: any = null;
  bier: bier = null;
  persoon: persoon;
  @Output('persoonklik') persoonklik = new EventEmitter<persoon>();

  constructor(injector: Injector) {
    super(injector, 'debug');
  }

  /**
   * Setter voor een proefitem
   * @param item
   */
  @Input() set item(item: mainitem_geproefd) {
    // this.log.debug('maingeproefd init', item);
    (async () => {
      this.proefitem = item;
      this.persoon = item.gebruiker ? (await this.api.persoon(item.gebruiker, false, true)) : null; //er komen ook geproefd items zonder gebruiker: bijvoorbeeld iemand die op een bekende locatie proeft
      this.bier = await this.api.bierdata(item.bier, true);
    })();
  }

  /**
   * Return de ava-src voor het item
   */
  get ava(): string {
    switch (this.proefitem.subtype)
    {
      case 'vriend':
        return (this.persoon && this.api.geturl(this.persoon.ava)) || 'assets/imgs/persoon_default.svg';
      case 'locatie':
        return "assets/svg/maps_bierlocatie.svg";
    }
  }

  /**
   * De ava is niet op te halen (bijv bij FB CDN-problemen). We zetten een default in de plaats
   * @param event
   */
  avaError(event) {
    console.warn(`Error getting ava`, event.target.src);
    try {
      event.target.src = 'assets/imgs/persoon_default.svg';
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Op persoon geklikt. Stuur door naar parent
   */
  naarPersoon() {
    if (this.persoon) {
      super.naarPersoon(this.persoon);
    }
  }
}
