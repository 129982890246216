/*
* mainmelding.ts
* 13-1-2020 - Jelmer Jellema - Spin in het Web B.V.
*
* Directive voor het afbeelden van een melding van achter
*/

import {Component, Injector, Input} from "@angular/core";
import {BeerproComponent} from "../../../abstracts/beerprocomponent";
import {mainitem_melding} from "../../../beerprotypes";

@Component({
  selector: 'mainmelding',
  templateUrl: './mainmelding.html',
  styleUrls: ['alle_items.scss','mainmelding.scss']
})
export class MainmeldingComponent extends BeerproComponent {
  @Input() item: mainitem_melding = null;

  constructor(injector: Injector) {
    super(injector, 'debug');
  }

  /**
   * Zorg voor klik naar route of url
   */
  naarItem() {
    if (this.item) {
      if (this.item.url) {
        this.api.openBrowser(this.item.url);
      }
    }
  }
}
