/*
* modals.module.ts
* 24-12-2021 - Jelmer Jellema - Spin in het Web B.V.
*
* Centrale plek voor de modules. Er is niet echt een reden om ze niet allemaal te laden toch?
*/

import {NgModule} from "@angular/core";
import {Agepicker} from "./agepicker/agepicker";
import {BasisModule} from "../basis.module";

@NgModule({
  declarations: [
    Agepicker
  ],
  imports:[BasisModule],
  providers: []
})
export class ModalsModule {}
