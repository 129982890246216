/**
 Abstracte parentclass van allerlei beerpro-objecten. Doet zeer algemene zaken, zoals
 de logger en helpers voor subscriptions

 Een BeerproComponent constructor moet de Injector injecteren en doorgeven aan de super
 de constructor kan beter niet weggelaten worden, dat geeft resolve-problemen
 **/
import {Directive, Injector, OnDestroy} from "@angular/core";
import {Subscription} from "rxjs";
import {Sihwlog, Sihwlogger} from "sihw-ng/sihwlog/sihwlog";
import {Api} from "../services/api/api";
import {ToastController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import * as moment from 'moment';
import {Router} from "@angular/router";
import { bier, persoon} from "../beerprotypes";

@Directive()
export abstract class BeerproComponent implements OnDestroy {
  private subscriptions: Subscription;
  public log: Sihwlogger;
  private huidigeToast: HTMLIonToastElement = null;

  //injectables:
  protected api: Api;
  public router: Router; //public router
  protected toast: ToastController;
  protected translate: TranslateService;


  protected constructor(injector: Injector, loglevel: string = 'info') {
    this.log = injector.get(Sihwlog).logger(loglevel, this.constructor.name);
    this.api = injector.get(Api);
    this.router = injector.get(Router);
    this.toast = injector.get(ToastController);
    this.translate = injector.get(TranslateService);
  }


  async ionViewWillEnter() {
  }

  ngOnDestroy() {
    //verwijder lopende subscriptions
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  /**
   * Voeg een subscription toe aan dit object, wordt automatisch verwijderd bij destroy
   * @param sub
   */
  protected subscription(sub: Subscription) {
    if (this.subscriptions) {
      this.subscriptions.add(sub);
    } else {
      this.subscriptions = sub;
    }
  }

  ////////////////// Navigaties /////////////


  /**
   * Open bierdetails voor het gegeven biertje, een bier-object of een id
   * @param bier
   */
  async naarBierDetails(bier: bier | number) {

    await this.router.navigate(['/bier', typeof(bier) === 'number' ? bier : bier.id]);
  }

  naarPersoon(persoon: persoon) {
    this.log.warn(`naarpersoon`, persoon);
    //    void this.navCtrl.push('020dprofiledetails', {vriend: this._persoon});
  }
  ////////////////////////////////////////////

  /**
   * Toon een toast
   * @param melding
   * @param [tijd]
   * @param [isFout]
   */
  protected async doeToast(melding, tijd?, isFout?) {
    this.log.debug(`doeToast`, melding, tijd, isFout);

    this.dismissToast();

    this.huidigeToast = await this.toast.create({
      message: melding,
      duration: tijd,
      buttons: isFout ? [
        {
          icon: 'close-outline',
        //  text: this.translate.instant('i.closebuttontext'),
          role: 'cancel'
        }
      ] : [],
      cssClass: isFout ? 'foutmelding' : undefined
    });
    await this.huidigeToast.present();
  }

  /**
   * Verwijder de huidige toast
   */
  protected dismissToast() {
    if (this.huidigeToast) {
      void this.huidigeToast.dismiss();
      this.huidigeToast = null;
    }
  }

  /**
   * Shortcut voor translations
   * @param code
   * @param params
   */
  protected t(code: string, params?: any): string {
    return this.translate.instant(code, params);
  }

  /* relatieve datum ipv relatieve tijd (wat moment.fromNow doet)
  dit zorgt ervoor dat vandaag niet in uren vanaf middernacht wordt weergegeven
  a.vandaag en a.gisteren worden gebruikt
 */

  protected dateFromNow(m: moment.Moment): string {
    let fromNow = m.fromNow();
    return m.calendar(null, {
      sameDay: `[${this.t('a.vandaag')}]`,
      lastDay: `[${this.t('a.gisteren')}]`,
      lastWeek: `dddd`,
      nextDay: `[${fromNow}]`,
      sameElse: `[${fromNow}]`
    });
  }
}
