/**
 * Directive voor standaard afbeelden van bier
 <bier [bier]="model..." [proef]="<alternatieve proefdata>" [forceerProef]="<boolean: false>" [proef2]="<secondaire proefdata>" [proefmark]="<boolean: true>" [disabled]=<boolean=false> [naam]=<boolean=true> [logo]=<boolean=true>[rating]=<boolean=false> [waarden]=<boolean=false> [brouwer]=<boolean=true> [biertype]=<boolean=false> [geproefd]=<boolean=false> [geproefdfromnow]=<boolean=false> [push]=<boolean=true> end-icon=<ion-icon-name=null> (itemclick)="<functie>" (icon-click)="<functie>"  (itempress)=<functie>
 [bierdetails]= "click|press"
 slideIcon="<iconnaam = null>" slideColor="<iconkleur = null>" (itemslide)=<functie(bier)>
 [compact]=<boolean=false>></bier>

 Het biermodel moet een std-bier uit het backend zijn. Hieruit worden altijd de marks voor proef en lijsten gehaald. Proefdata kan eventueel wel apart worden meegestuurd, dan wordt die gebruikt voor het
 afbeelden in plaats van de proefdata in het std-bier.
 Er is optioneel een proef2, die is altijd apart van de data in het std-bier en wordt onder de gewone proefdata afgebeeld. Bijv gemiddeld over proeverij of aantal keren. Daarin kan ook een .num zitten, een aantal dat wordt afgebeeld

 Wanneer proefmark of lijsten missen en wel moeten worden afgebeeld, zal deze informatie gecached worden opgehaald
 **/
import {AfterViewInit, Component, EventEmitter, Injector, Input, Output, ViewChild} from "@angular/core";
import * as moment from 'moment';
import {BeerproComponent} from "../../abstracts/beerprocomponent";
import {proefdata, bier} from "../../beerprotypes";
import {IonItemSliding} from "@ionic/angular";

@Component({
  selector: 'bier',
  templateUrl: './bier.html',
  styleUrls: ['./bier.scss']
})
export class BierComponent extends BeerproComponent implements AfterViewInit {
  //biertje zelf is een getter / setter
  @Input() proef: proefdata = null; //alternatieve proefdata
  @Input() proef2: proefdata = null; //secondaire proefdata, bijv gemiddelde voor proeverij
  @Input() forceerProef: boolean = false; //forceer gebruik alternatieve proefdata, ook als die voor dit biertje mist
  @Input() proefmark: boolean = true; //toon vinkje
  @Input() disabled: boolean = false; //disable het event
  @Input() push: boolean = true; //toon push-icon
  @Input('end-icon') endIcon: string; //toon een ion-icon op de plek van de push. Push én end-icon aangeven is vragen om problemen
  @Input() naam: boolean = true; //toon naam
  @Input() logo: boolean = true; //toon logo
  @Input() waarden: boolean = false; //toon ibu en abv
  @Input() brouwer: boolean = true; //toon de brouwer
  @Input() biertype: boolean = false; //toon het biertype
  @Input() rating: boolean = false; //toon de rating
  @Input() geproefd: boolean = false; //toon mijn laatste keer proeven.
  @Input() geproefdfromnow: boolean = false; //gebruik from-now tijden ipv exact
  @Input() compact: boolean = false; //allemaal wat compacter
  @Input() bierdetails: 'click' | 'press' = null; //toon bierdetails bij click of longpress (via push)
  @Input() slideIcon: string = null; //als gegeven dan kan het item naar links sliden (als het in een ion-list zit) en toont het bij slide dit icon + het itemswipe event
  @Input() slideColor: string = null; //afwijkende kleur voor het slideicon. Bijvoorbeeld 'danger'

  @Output() itemclick = new EventEmitter<bier>(); //run bij click
  @Output() itempress = new EventEmitter<bier>(); //run bij longpress
  @Output() itemslide = new EventEmitter<bier>(); //slide naar links - als er een slideIcon gegeven is
  @Output('icon-click') iconClick = new EventEmitter<any>();

  @ViewChild('slider') slider: IonItemSliding;

  private slideOverGrens: boolean = false; //true als onSlide detecteert dat er over de grens is geslide. Een mouseup/touchent doet dan het slide-event

  constructor(injector: Injector) {
    super(injector, "debug");
    this.subscription(this.api.onChange({
      next: e => {
        if (this._bier && e.msg === "bierGewijzigd" && e.data === this._bier.id) {
          //we halen alleen de markers opnieuw op
          void this.checkBierdetails(true);
        }
      }
    }));
  };

  public _bier: bier = null;
  @Input() set bier(bier: bier) {
    this._bier = bier;
    void this.checkBierdetails();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.endIcon) {
        this.push = false; // even goed zetten
      }
    });
  }

  /**
   * Controleer of in het biertje al lijsten en geproefd zitten. Anders opnieuw ophalen
   */
  async checkBierdetails(force: boolean = false): Promise<void> {
    if (this._bier && this._bier.id && this._bier._bron === "bp" && (force || (!(this._bier.lijsten && ("geproefd" in this._bier))))) {
      //haal op
      this.log.debug(`Ophalen bierdetails ${this._bier.naam || this._bier.id}`);
      let bd = await this.api.bierdata(this._bier.id); //hopelijk gecached
      if (bd.lijsten) {
        this._bier.lijsten = bd.lijsten;
      }
      if ("geproefd" in bd) {
        this._bier.geproefd = bd.geproefd;
      }
    }
  }

  /**
   * Geef de te gebruiken proefdata terug. Dat is of onze this.proef, of de proef van bier
   */
  get proefdata(): proefdata {
    return this.proef || ((!this.forceerProef) && (this._bier && this._bier.proef)) || null;
  }

  /**
   * Maak het biermoment
   */
  biermoment(): string {
    if (!(this.geproefd && this.proefdata && this.proefdata.moment)) {
      return "";
    }
    let m = moment(this.proefdata.moment, "YYYYMMDD");
    return this.geproefdfromnow ? this.dateFromNow(m) : m.format('LL');
  }

  /**
   * Handle slide. Werkt niet goed met ionSwipe. Dus daarom pakken ionDrag
   * We flaggen hier wanneer men over de grens is. In de touchend handler van het echte ion-item
   * volgt dan eventueel het event (zie onCheckEndSlide)
   * Hierdoor zitten we niet on touchmove-handlers e.d. als het item bijv verwijderd wordt.
   */
  async onSlide() {

    //we moeten verder open sliden dan 1. Want exact 1 is wat er soms gebeurt als je een gesture
    if ((!this.slideIcon) || (!this._bier) || this.disabled || Math.abs(await this.slider.getSlidingRatio()) <= 1) {
      //slideovergrens is dus niet meer
      this.slideOverGrens = false;
      return; //niet van toepassing
    }

    //het enige dat we hier doen is SlideOverGrens goed zetten
    this.slideOverGrens = true;
  }

  /**
   * Handler van touchend / mouseup op de button zelf. Is dit een sllide-end?
   */
  async onCheckEndSlide() {
    if (this.slideOverGrens) {
      //hoe dan ook: weer dicht
      await this.slider.close();
      //emit het
      this.itemslide.emit(this._bier);
      this.slideOverGrens = false; //niet meer
    }
  }

  onSwipe() {
    this.log.warn('*** swipe');
  }

  onDragend() {
    this.log.debug(`dragend!!`);
    this.slider.close();
  }

  /**
   * De select-handler
   */
  async btnclick() {
    if (this._bier && (!this.disabled)) {
      if (this.bierdetails === 'click' && this._bier.id && this._bier._bron === "bp") {
        await this.naarBierDetails(this._bier);

        //toon de details, en emit als de nav klaar is
      }
      this.itemclick.emit(this._bier);
    }
  }

  /**
   * Zelfde voor press
   */
  async btnpress(event) {
    this.log.debug('bierpress', this.bierdetails);
    if (this._bier && (!this.disabled)) {
      if (this.bierdetails === 'press' && this._bier.id && this._bier._bron === "bp") {
        //toon de details, en emit als de nav klaar is
        await this.naarBierDetails(this._bier);
      }
      this.itempress.emit(this._bier);
    }
  }

  /**
   * De handler van clicken op alleen het icon
   * Een handler voor het hele item én het icon is vragen om problemen
   */
  iconclick() {
    if (this._bier && (!this.disabled)) {
      this.iconClick.emit(this._bier);
    }
  }
}
