/*
* directives.module.ts
* 26-11-2021 - Jelmer Jellema - Spin in het Web B.V.
*
* Helperdirectives
*/
import {NgModule} from "@angular/core";
import {TComponent} from "./t";
import {TranslateModule} from "@ngx-translate/core";
import {StickyOnderComponent} from "./stickyonder";
import {SihwlogModule} from "sihw-ng/sihwlog/sihwlog.module";
import {CommonModule} from "@angular/common";

const components = [
  TComponent,
  StickyOnderComponent
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    SihwlogModule,
  ],
  declarations: components,
  exports: components
})
export class DirectivesModule {}

