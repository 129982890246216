/*
* privatepage.ts
* 26-11-2021 - Jelmer Jellema - Spin in het Web B.V.
*
* Een abstracte parent. Gewoon een BeerPropPage met beveiliging = true. Dit vanwege de ionic-4 codebase, die dat zo deed
*/

import {BeerproPage} from "./beerpropage";
import {Directive} from "@angular/core";

@Directive()
export abstract class PrivatePage extends BeerproPage {
  public static beveiligd = true;
}
