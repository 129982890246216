/*
* basis.module.ts
* 6-7-2021 - Jelmer Jellema - Spin in het Web B.V.
*
* Services en components die in elke module nodig zijn
*/

import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {IonicModule} from "@ionic/angular";
import {AppRoutingModule} from "./app-routing.module";
import {SihwcommentModule} from "sihw-ng/sihwcomment/sihwcomment.module";
import {SihwlogModule} from "sihw-ng/sihwlog/sihwlog.module";
import {ApiModule} from "./services/api/api.module";
import {TranslateModule} from "@ngx-translate/core";
import {DirectivesModule} from "./directives/directives.module";

const modules = [
  CommonModule,
  FormsModule,
  AppRoutingModule,
  IonicModule,
  TranslateModule,
  //sihw-ng
  SihwlogModule,
  SihwcommentModule,
  //onze eigen standaardzaken
  DirectivesModule,
  ApiModule
];

@NgModule({
  imports: modules,
  exports: modules
}) export class BasisModule {}
