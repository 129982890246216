/*
* mainpersoon.ts
* 13-1-2020 - Jelmer Jellema - Spin in het Web B.V.
*
* Directive voor het afbeelden van vriendverzoek of nieuwe vriend in main
*/

import {BeerproComponent} from "../../../abstracts/beerprocomponent";
import {Component, Injector, Input} from "@angular/core";
import { mainitem_persoon, persoon} from "../../../beerprotypes";
import * as moment from "moment";

@Component({
  selector: 'mainpersoon',
  styleUrls: ['alle_items.scss','mainpersoon.scss'],
  template: `
    <ion-card>
      <ion-card-header (click)="naarPersoon()">
        <ion-avatar><img class="ava" alt="" [src]="ava"
                         (error)="avaError($event)"></ion-avatar>
        <t [params]="persoon" *ngIf="item?.itemtype === 'nieuwevriend'">s.main.tl.nieuwevriend</t>
        <t [params]="persoon" *ngIf="item?.itemtype === 'vriendverzoek'">s.main.tl.uitgenodigd</t>
      </ion-card-header>
      <ion-card-content>
        <persoon [persoon]="persoon" push="detail" [vriendstatus]="false" [proef]="false">
          <div class="maindata" *ngIf="item?.moment">{{itemmoment()}}</div>
          <div class="maindata" *ngIf="item?.itemtype === 'nieuwevriend'"><t>s.main.tl.nieuwevriend_actie</t></div>
        </persoon>
      </ion-card-content>
    </ion-card>
  `
})
export class MainpersoonComponent extends BeerproComponent {
  item: mainitem_persoon = null;
  persoon: persoon;
  kortenaam: string;

  constructor(injector: Injector) {
    super(injector, 'debug');
  }

  /**
   * Setter voor het item
   * @param item
   */
  @Input('item') set initem(item: mainitem_persoon) {
    this.log.debug('mainpersoon init', item);
    (async () => {
      this.item = item;
      this.persoon = await this.api.persoon(item.gebruiker, false, true);
    })();
  }

  public itemmoment(): string {
    if (! (this.item && this.item.moment))
    {
      return "";
    }
    return this.dateFromNow(moment(this.item.moment, "YYYYMMDD"));
  }

  /**
   * Return de ava-src voor het item
   */
  get ava(): string {
    return (this.persoon && this.api.geturl(this.persoon.ava)) || 'assets/imgs/persoon_default.svg';
  }

  /**
   * De ava is niet op te halen (bijv bij FB CDN-problemen). We zetten een default in de plaats
   * @param event
   */
  avaError(event) {
    console.warn(`Error getting ava`, event.target.src);
    try {
      event.target.src = 'assets/imgs/persoon_default.svg';
    } catch (e) {
      console.error(e);
    }
  }

  naarPersoon() {
    if (this.persoon) {
      super.naarPersoon(this.persoon);
    }
  }
}
