import {Component, Injector, Input} from "@angular/core";
import {BeerproComponent} from "../abstracts/beerprocomponent";

//simpele snellere translate
@Component({
    selector: 't',
    template: `<span translate [translateParams]="params"><ng-content></ng-content></span>`
}) export class TComponent extends BeerproComponent{
    @Input() params: any; //translateparams

  public constructor(injector: Injector)
  {
    super(injector);
  }
}
