/*
* beerpromodal.ts
* 24-12-2021 - Jelmer Jellema - Spin in het Web B.V.
* Abstracte class voor modals (pickers). Let op erft van BeerProcomponent, niet van een page. Geen route dus.
*
*/

import {Directive, Injector} from "@angular/core";
import {BeerproComponent} from "../abstracts/beerprocomponent";
import {ModalController} from "@ionic/angular";

@Directive()
export abstract class BeerproModal extends BeerproComponent {
    public modalController: ModalController; //voor gebruik binnen de modal
    protected constructor(injector: Injector, loglevel: string = 'info') {
      super(injector, loglevel);
      this.modalController = injector.get(ModalController);
    }

  /**
   * Dismiss de modal met een bepaald resultaat
   * @param result
   */
  async dismiss(result: any) {
      await this.modalController.dismiss(result);
    }
}
