import {NgModule} from "@angular/core";
import {Api} from "./api";
import {Spinjs2Module} from "sihw-ng/spinjs2/spinjs2.module";
import {SihwsemaphoreModule} from "sihw-ng/semaphore/sihwsemaphore.module";
import {SihwlogModule} from "sihw-ng/sihwlog/sihwlog.module";

@NgModule({
  declarations: [],
  imports: [
    Spinjs2Module,
    SihwsemaphoreModule,
    SihwlogModule
  ],
  providers: [Api]
}) export class ApiModule {}
