/*
* Main.module.ts
* 14-1-2022 - Jelmer Jellema - Spin in het Web B.V.
*
* Aparte module ivm de subitems
*/


import {NgModule} from "@angular/core";
import {MainPage} from "./main";
import {BasisModule} from "../../basis.module";
import {MainmeldingComponent} from "./items/mainmelding";
import {MaingeproefdComponent} from "./items/mainbiergeproefd";
import {ComponentsModule} from "../../components/components.module";
import {MainpersoonComponent} from "./items/mainpersoon";

@NgModule({
  declarations: [
    MainPage,
    /* BiergeproefdComponent,
     MainproeverijComponent,
     */
    MainmeldingComponent,
    MaingeproefdComponent, MainpersoonComponent,
  ],
  imports: [
    BasisModule,
    ComponentsModule
  ],
})
export class MainPageModule {
}
