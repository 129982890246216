/**
 Abstrace parentclass voor alle pagina's, publiek en niet-publiek.
 Beveiliging wordt gecheckt via de CheckLoggedin guard die bij elke painga in app-routing wordt toegevoegd

 BeerproComponent
 - BeerproPage
 --- PrivatePage
 --- <specifieke pagina's zoals splash)

 Een beeproPage moet de Injector injecteren en doorgeven in de constructor
 * Nodig in concrete subpages:
 *  - Optioneel: set de static propery beveiligd. Standaard is dit false  Als true, dan is de page alleen beschikbaar bij beveiliging. Let op: je kan ook subclassen van PrivatePage, enige verschil is dat daar beveiligd true is
 *  - Er is geen aparte baseOnInit ofzo. Je kan gewoon ngOnInit of ionViewWillenter etc gebruiken
 *  - Er is hier wel een ngOnDestroy. Dus super aanroepen
 *  - Let op: in ionic wordt ngOnInit alleen aangeroepen als de dom gemaakt wordt. Bij terugpoppen naar een page dus niet. Als data ververst moet worden bij elke keer dat de pagina terugkomt, kan je beter ionViewWillEnter of ionViewDidEnter gebruiken. Zie https://ionicframework.com/docs/v5/angular/lifecycle
 */
import {Directive, Injectable, Injector, OnDestroy} from "@angular/core";
import {BeerproComponent} from "./beerprocomponent";
import {
  ActivatedRoute,
  ActivatedRouteSnapshot, CanActivate,
  CanDeactivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from "@angular/router";
import {ModalController} from "@ionic/angular";
import {BeerproModal} from "../modals/beerpromodal";
import {hash} from "../beerprotypes";
import {Api} from "../services/api/api";
import {Sihwlog, Sihwlogger} from "sihw-ng/sihwlog/sihwlog";

@Directive() //dit is sinds angular 10 verplicht in baseclasses met angular behaviour
export abstract class BeerproPage extends BeerproComponent implements OnDestroy {
  /**
   * Beveiliging via Checkloggedin guard. Als een subclass deze op true heeft staan, zal de guerd doorgeleiden naar splash als niet ingelogd.
   * Privatepage subclass heeft deze op true staan
   * @protected
   */
  public static beveiligd: boolean = false; //moet public zijn ivm guard

  public route: ActivatedRoute; //de huidige route, voor snapshot
  public modalController: ModalController; //voor de modals

  protected constructor(injector: Injector, loglevel?: string) {
    super(injector, loglevel);
    this.route = injector.get(ActivatedRoute);
    this.modalController = injector.get(ModalController);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    //STUB
  }


  /**
   * Deze call wordt aangeroepen als een route naar een baseComponent CanDeactivateBasepage als guard heeft. Moet een boolean of nieuwe url teruggeven, zoals de CanDeactivate guard dat doet
   */
  async canDeactivate(): Promise<boolean | UrlTree> {
    return true; //mag hoor
  }


  //////////////////////// MODALS ////////////////////////////////////
  //Werkt met BeerproModal modals

  protected async getModal(modalComponent: typeof BeerproModal, params: hash<any> = {}) {
    const modal = await this.modalController.create({
      component: modalComponent,
      cssClass: 'beerpromodal', //voor overrules, zie https://ionicframework.com/docs/v5/api/modal
      componentProps: params
    });
    this.log.debug(`getModal open`, modalComponent.name);
    await modal.present();
    let res = await modal.onDidDismiss();
    this.log.debug(`getModal return`, modalComponent.name, res);
    return res.data; //returnt als de modal dicht is
  }

  ////////////////////// Snelle getters / helpers //////////////////////
  /**
   * Haal de waarde van een route-param op. Via snapshot, dus we gaan uit van herladen van route-componenten
   * @param naam
   */
  public param(naam: string): any {
    return this.route ? this.route.snapshot.paramMap.get(naam) : null;
  }

  /**
   * Haal de waarde van een getparam op. Via snapshot, dus we gaan uit van herladen van route-componenten
   * @param naam
   */
  public getParam(naam: string): any {
    return this.route ? this.route.snapshot.queryParamMap.get(naam) : null;
  }

  /////////////////// Routes ////////////////////////////
  //snelle calls voor bepaalde routes. Ze zijn public voor snelle acties in handlers in de templates
  /**
   * Ga naar splash, en dus main, eventueel met terugkominfo over de pagina waarvandaan
   * @param direct = false Doe het binnen de huidige cycle. Standaard wachten we tot deze af is, want vaak leveren calls een reset op de router op
   */
  public naarSplash(direct: boolean = false): void {
    if (direct) {
      //dus nu
      this.router.navigate(['/splash']);
    } else {
      setTimeout(() => {
        this.naarSplash(true)
      });
    }
  }
}

export class CanDeactivateBeerpropage implements CanDeactivate<BeerproPage> {
  canDeactivate(component: BeerproPage, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return component.canDeactivate();
  }
}

@Injectable()
export class Checkloggedin implements CanActivate {
  private log: Sihwlogger;

  constructor(private api: Api, private router: Router, sihwlog: Sihwlog) {
    this.log = sihwlog.logger('debug', this.constructor.name);
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    //we doen dit zo algemeen mogelijk. We veronderstellen niet BeerproPage,
    //maar verwachten een statische eigenschap beveiligd
    let page: any = route.routeConfig.component; //sorry, het gehussel met typeof en classes en prototypes wordt me teveel

    if (!(page && ('beveiligd' in page))) {
      //we gaan er niet over
      this.log.error(`Checkloggedin werkt alleen bij Beerpropage`, page);
      return false; //voor de zekerheid
    }
    if (!(<any>page).beveiligd) {
      this.log.debug(`Publieke pagina`, page.name);
      return true; //en door
    }
    //beveiligde pagina
    if (!await this.api.loggedIn()) {
      this.log.warn(`Beveiligde pagina`, page.name);
      return this.router.parseUrl('/splash');
    }
    this.log.debug(`Toegang gecheckt`, page.name);
    return true;
  }
}
