/*
* main.ts
* 26-11-2021 - Jelmer Jellema - Spin in het Web B.V.
*/

import {Component, Injector, OnInit, ViewChild} from "@angular/core";
import {PrivatePage} from "../../abstracts/privatepage";
import {IonContent} from "@ionic/angular";
import {hash, mainitem_welkom, mainlijst, persoon} from "../../beerprotypes";

@Component({
  templateUrl: './main.html',
  styleUrls: ['./main.scss']
})
export class MainPage extends PrivatePage implements OnInit {
  @ViewChild(IonContent, {static: false}) content: IonContent;

  public openviltje = "-"; //l or r of -
  public uitnodigingen = [];
  public welkom: mainitem_welkom = null; //data voor het welkomblok

  public items = []; //de af te beelden items

  private laatstGehaald: number = 0;
  private forceerHalen: boolean = false; //als true, dan wordt er altijd opnieuw gehaald in ionViewDidenter

  constructor(injector: Injector) {
    super(injector, 'debug'); //regels de injections
  }

  ngOnInit() {
    this.log.debug('*** ngOninit MainPage');
    //de subscription doet alleen wat dingen als je niet in de main zit
    this.subscription(this.api.onChange({
      next: e => {
        this.log.debug(`Main observer`, e);
        //bij welke notificaties is het interessant?
        //bier zelf hoeft namelijk niet
        this.forceerHalen = (e.msg === "proeverij") || (e.msg === "refresh");
        //volgende keer opnieuw halen
      }
    }));

  }

  ionViewDidEnter() {
    //zijn er nog popups
    this.log.debug(`ionViewDidEnter`);
    // this.log.debug('child', this.navCtrl.getActiveChildNavs());
    //opnieuw halen?
    if (this.forceerHalen || (Date.now() - this.laatstGehaald > 60000)) {
      void this.haalData();
    }
  }


  public logout() {
    this.api.logout();
    this.naarSplash();
  }


  //////////////////////// DATA //////////////////////////////////////
  private async haalData(): Promise<void> {
    //eerst halen, dan zetten, omdat steeds anders
    let nieuw: mainlijst = await this.api.maindata();
    if (nieuw) {
      this.log.debug(nieuw);
      this.welkom = nieuw.welkom;
      //in plaats van opnieuw, gaan we aanpassen in een loop, kijken of dat beter werkt

      //simpelste versie: vind hetzelfde object
      //deze objecten moeten dus zelf wel hun data checken
      let olditems = this.items;
      this.items = [];
      for(let item of nieuw.items)
      {
        this.items.push(olditems.find(olditem => olditem.id === item.id) || item); //behoud het object
      }

      this.laatstGehaald = Date.now();
      this.forceerHalen = false;
    }
  }

  async refresh(event) {
    this.log.debug(`Refresh`);
    await this.haalData();
    event.target.complete(); //dat is de ionRefresher
    //todo: next-handler
  }
  //klik op tool = scroll terug
  scrollTerug() {
    this.log.debug(this.content);
    this.haalData();
    this.content.scrollToTop();
  }


  //track de ngFor by id
  public trackById(index: number, item: any) {
    return item.id;
  }

  /////////////////////////// Item events ///////////////////////////


  /////////////////////////// viltjes /////////////////////////////////

  public toggleviltje(viltje) {
    this.log.debug(`toggleviltje`, viltje);

    if (this.openviltje === viltje) {
      this.openviltje = '-'; //dicht
    } else {
      this.openviltje = viltje; //eventueel de andere dicht
    }
  }

  //regel een klik op het viltje
  public vc($event: Event, page: string, params: hash<any> = {}, methode: string = 'push'): void {
    //TODO: dit moet over want die paginas gaan niet meer op string
    this.log.warn(`TODO: nieuwe navigatie`);
    /*
    $event.stopPropagation();
    this.openviltje = '-';


    if (methode === 'push') {
      void this.navCtrl.push(page, params);
    } else {
      void this.navCtrl.setRoot(page, params);
    }
    // return false;
    */
  }

  get voornaam(): string {
    return (this.api && this.api.userdata && this.api.userdata.naam && this.api.userdata.naam.split(/\s/)[0]) || "";
  }

  get ava(): string {
    return (this.api && this.api.userdata && this.api.userdata.ava && this.api.geturl(this.api.userdata.ava)) || 'assets/imgs/persoon_default.svg';
  }

  //special voor browsermodus in dev

  kanfullscreen(): boolean {
    //uitgeschakeld
    return false;
    /*  return document && !!document.exitFullscreen;*/

    // platform.is("mobileweb") && (!! document.exitFullscreen);
  }

  toggleFullscreen() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }
}
