import {Component, Injector, OnInit} from '@angular/core';
import {BeerproPage} from "../../../abstracts/beerpropage";
//native inloggen

@Component({
  templateUrl: './login.html',
  styleUrls: ['../loginflow.scss']
})
export class LoginPage extends BeerproPage implements OnInit {
  credentials = {
    email: "",
    password: "",
    code: ''
  };
  codenodig: boolean = false; //is er om een inlogcode gevraagd?

  //onze super doet de meeste injections, vandaar dat we initialiseren met een injector
  constructor(injector: Injector) {
    super(injector);
  }

  /**
   * Vang de viewdidload op
   */
  async ngOnInit () {
    //expliciete uitloglink?
    if (this.getParam('loguit'))
    {
      this.api.logout();
      this.naarSplash();
      return;
    }
    if (await this.api.loggedIn())
    {
      this.naarSplash();
      //en klaar
      return;
    }
    this.credentials.email = this.param('email') || "";
    this.credentials.password = this.param('password') || "";
    this.codenodig = this.param('codenodig') || false;
  }

  async login() {
    this.log.debug(`Login`, this.credentials);
    await this.doeToast(this.translate.instant('s.login.logging_in'));

    let errcode: string = await this.api.nativeLogin(this.credentials.email, this.credentials.password, this.codenodig ? this.credentials.code : null);
    this.dismissToast();
    this.log.debug('errcode', errcode);
    if (!errcode) {
      //gelukt!
      this.naarSplash(); //splash mag regelen waar we heen gaan
    } else if (errcode === "CODE_NODIG") {
      this.doeToast(this.translate.instant('s.login.codenodig'), 10000);
      this.codenodig = true;
    } else if (errcode === "NIEUWE_CODE_NODIG") {
      //er is zojuist een nieuwe verstuurd
      this.doeToast(this.translate.instant('s.login.nieuwecodenodig'), 10000);
      this.codenodig = true;
    } else {
      //mislukt
      let key;
      switch (errcode) {
        case 'NO_AUTH':
          key = 'invalid_credentials';
          break;
        case 'OVER_QUOTA':
          key = 'teveel_pogingen';
          break;
        case 'OLD_VERSION':
          return; //dan is er namelijk al een toast geweest
        default:
          key = 'error';
          break;
      }
      this.doeToast(this.translate.instant(`s.login.${key}`), 10000, true);
    }
  }

  /**
   * Verzoek om een nieuwe code te sturen
   */
  async nieuwecode() {
    if (this.credentials.email) {
      this.doeToast(this.translate.instant('s.login.codevragen'));
      if (await this.api.nieuweCode(this.credentials.email)) {
        this.doeToast(this.translate.instant(`s.login.codegevraagd`, 10000));
      } else {
        this.doeToast(this.translate.instant('s.login.error'), 10000, true);
      }
    }
  }

}
