/**
 * Bierdetails: wanneer heb ik het geproefd?
 * Binnenkomt het standaardbier-object uit een andere lijst
 */
import {Component, Injector, OnInit} from "@angular/core";
import {PrivatePage} from "../../abstracts/privatepage";


const designmode = false;

@Component({
  selector: 'page-bier-details',
  templateUrl: 'bier-details.html',
  styleUrls: ['./bier-details.scss']
})
export class BierDetailsPage extends PrivatePage implements OnInit {
  bier: any; //het geladen biertje
  geschiedenis: any;
  alleProefs: boolean = false;

  constructor(injector: Injector) {
    super(injector, 'debug');

    this.subscription(this.api.onChange({
      next: e => {
        this.log.debug(`Apichange`, e);
        switch (e.msg) {
          case 'bierGewijzigd':
            if (e.data === this.bier.id) {
              void this.haalData(); //opnieuw halen
            }
        }
      }
    }));
  }

  async ngOnInit() {
    await this.haalData();
  }


  private async haalData() {
    //haal de data (opnieuw). Eerste keer is dat meestal uit cache
    this.log.debug(`Haaldata`, this.param('bierId'));
    this.bier = await this.api.bierdata(this.param('bierId'));
    if (!(this.bier && this.bier.id)) {
      this.log.error(`Bier niet gevonden`);
      this.naarSplash();
      return;
    }
    this.geschiedenis = await this.api.geschiedenis(this.bier.id, this.alleProefs);
    //geschiedenis heeft .zelf en .meerzelf. .meerzelf is een boolean die aangeeft of er nog meer zijn dan teruggegeven (als uitgebreid = false)
  }

  /**
   * Toggle de toevoeging aan de lijst
   * @param lijst
   */
  async toggleLijst(lijst) {
    //uitbesteden
    let res = await this.api.toggleLijst(this.bier.id, lijst);
    if (res) {
      this.bier = res;
    }
  }

  opKaart() {
    this.log.warn(`opKaart moet nog`);
    /*     void this.navCtrl.push('014bierindebuurt', {bier:this.bier});*/
  }

  proefdetails(proef) {
    this.log.warn(`proefdetails moeten nog`);
    /*    this.log.debug(proef);
        void this.navCtrl.push('012biertjeproeven',{bier: this.bier, proef: proef.id});*/
  }

  /**
   * Toon alle proefdetails
   */
  meerDetails() {
    this.alleProefs = true;
    void this.haalData();
  }

}
