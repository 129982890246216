/*
* agepicker.ts
* 24-12-2021 - Jelmer Jellema - Spin in het Web B.V.
*
* Modal voor age-check
*/

import {Component, Injector} from "@angular/core";
import {BeerproModal} from "../beerpromodal";

@Component({
  templateUrl: './agepicker.html',
  styleUrls: ['./agepicker.scss']
})
export class Agepicker extends BeerproModal {
  constructor(injector: Injector) {
    super(injector, 'debug');
  }
}
