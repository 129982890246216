/**
 * splashpagina, regelt de dispatch
 */

import {Component, Injector, OnInit} from "@angular/core";
import {BeerproPage} from "../../../abstracts/beerpropage";

@Component({
  templateUrl: './splash.html',
  styleUrls: ['./splash.scss']
})
export class SplashPage extends BeerproPage implements OnInit {
  constructor(inject: Injector) {
    super(inject, 'debug');
  }

  async ngOnInit() {
    //even async volgende pagina doen
    setTimeout(() => {
      void this.checkNextPage();
    }, 100);
  }

  ionViewDidEnter() {
    this.log.warn('ionViewDidEnter');
    setTimeout(() => {
      void this.checkNextPage();
    }, 100);
  }

  private async checkNextPage(): Promise<void> {
    await this.api.afterInit;
    this.log.debug(`Splash: api is klaar. Zijn we ingelogd?`);
    if (await this.api.loggedIn()) {
      this.log.debug(`Api in orde en ingelogd: naar main`);
      await this.router.navigate(['/main']);
    } else {
      let ooit = this.api.wasLoggedIn;
      this.log.debug(`Api in orde, maar niet ingelogd. Naar ${ooit ? 'login' : 'signup'}`);
      await this.router.navigate(ooit ? ['/login'] : ['/signup']);
    }
  }
}
