/**
 * Centrale plek voor de paginas
 */
import {NgModule} from "@angular/core";
import {BasisModule} from "../basis.module";
import {SignupPage} from "./loginflow/002-signup/signup";
import {LoginPage} from "./loginflow/006-login/login";
import {SplashPage} from "./loginflow/001-splash/splash";
import {SignupnativePage} from "./loginflow/004-signupnative/signupnative";
import {Checkloggedin} from "../abstracts/beerpropage";
import {MainPageModule} from "./000-main/Main.module";
import {ComponentsModule} from "../components/components.module";
import {BierDetailsPage} from "./022-bier-details/bier-details";


@NgModule({
  declarations: [
    SplashPage,
    SignupPage,
    SignupnativePage,
    LoginPage,
    BierDetailsPage
  ],
  imports: [BasisModule, ComponentsModule, MainPageModule],
  providers: [Checkloggedin]
})
export class PagesModule {}
