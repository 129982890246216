/*
* bovenbalk.ts
* 11-2-2022 - Jelmer Jellema - Spin in het Web B.V.
* <navbar>titel</navbar>
* De navbar in de schermen
*/

import {Component, Injector, Input} from "@angular/core";
import {BeerproComponent} from "../../abstracts/beerprocomponent";

@Component({
  selector: 'navbar',
  templateUrl: './navbar.html',
  styleUrls: ['./navbar.scss']
})
export class NavbarComponent extends BeerproComponent {
  constructor(injector: Injector) {
    super(injector);
  }
}
