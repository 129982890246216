import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {MainPage} from "./pages/000-main/main";
import {SignupPage} from "./pages/loginflow/002-signup/signup";
import {SplashPage} from "./pages/loginflow/001-splash/splash";
import {LoginPage} from "./pages/loginflow/006-login/login";
import {SignupnativePage} from "./pages/loginflow/004-signupnative/signupnative";
import {Checkloggedin} from "./abstracts/beerpropage";
import {BierDetailsPage} from "./pages/022-bier-details/bier-details";

const routes: Routes = [
  {
    path: 'main', component: MainPage
  },
  {
    path: 'splash', component: SplashPage
  },
  {path: 'signup', component: SignupPage},
  {path: 'signupnative', component: SignupnativePage},
  {path: 'login', component: LoginPage, canActivate: [Checkloggedin]},
  /*  {
      path: 'home',
      loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
    },*/
  {path: 'bier/:bierId', component: BierDetailsPage, canActivate: [Checkloggedin]},
  {
    path: '',
    redirectTo: 'splash',
    pathMatch: 'full'
  },
  {path: '**', redirectTo: 'splash'} //ook
];
//overal checkloggedin erin
for (let route of routes) {
  if (!route.redirectTo) {
    route.canActivate = route.canActivate || [];
    route.canActivate.push(Checkloggedin);
  }
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
