import {BeerproPage} from "../../../abstracts/beerpropage";
import {Component, Injector} from "@angular/core";
import {Agepicker} from "../../../modals/agepicker/agepicker";

/**
 Nieuwe aanmelding, inclusief de agecheck en bevestigingsmail-afhandeling
 */

@Component({
  templateUrl: 'signupnative.html',
  styleUrls: ['../loginflow.scss']
})
export class SignupnativePage extends BeerproPage {
  //onze super doet de meeste injections, vandaar dat we initialiseren met een injector

  ux = {
    naam: "",
    email: "",
    ww: "",
    ww2: ""
  };

  constructor(injector: Injector) {
    super(injector, 'debug');
  }

  /**
   * Implementeer ionViewDidLoad
   */
  async ionViewDidEnter() {
    this.log.debug(`OPEN AGEPICKER`);
    if (!await this.getModal(Agepicker)) {
      this.log.warn(`Agecheck mislukt`);
      //mag niet
      this.naarSplash();
      return;
    }
  }

  async aanmelden() {
    this.log.debug("Aanmelden", this.ux);

    await this.doeToast(this.translate.instant('s.signupnative.running'));
    let res = await this.api.nativeSignup(this.ux.naam, this.ux.email, this.ux.ww);
    void this.dismissToast();
    if (res.success) {
      //gelukt
      await this.doeToast(this.t('s.signupnative.succes', res.data), 6000);
      //TODO: hoe deze params meesturen?
      this.router.navigate(['/login', {intern: true, email: this.ux.email, password: this.ux.ww, codenodig: true}])
    } else {
      //mislukt
      let key = `s.signupnative.error.${res.errcode}`;
      let msg = this.translate.instant(key);
      if (msg == key) {
        //geen vertaling
        msg = this.translate.instant('s.signupnative.error.INTERNAL_ERROR');
      }
      this.doeToast(
        msg,
        10000,
        true);
    }
  }

}
