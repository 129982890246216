/*
<div class="eendivmeteenhoogte">
    <div stickyonder>
        Inhoud
    </div
</div>

Zorgt ervoor dat Inhoud helemaal onderaan in de parent wordt geplaatst, als het daar past. Is de parent te hoog om de inhoud te laten passen, dan plaatsen we hem gewoon in de flow onder de rest van de inhoud. Is de parent-inhoud veel lager, dan transformen we de stickyonder naar beneden

Met een parent die 100vh is, komt Inhoud dus beneden in het scherm als er ruimte is, maar onder de rest van de inhoud (scrollend dus) als het niet past. Nooit, zoals absolute bottom: 0, door de andere inhoud heen.
Maar: in bijv safari/ios chrome/android is de schermhoogte bij het begin gelijk aan de hoogte + de adresbalk die verdwijnt als je scrollt. Dus staat stickyonder te laag. Zet dan [fullbrowser]=true aan, als de container toch 100vh is. Dan werkt het goed in gewone browser, app/wpa en browser.

 */
//stickyonder div: plakt de inhoud als het past onderin zijn parent, zonder deze te vergroten. In 100% height content dus keurig onderaan het scherm

import {Component, ViewChild, AfterViewInit, Input} from "@angular/core";
import {Sihwlog, Sihwlogger} from "sihw-ng/sihwlog/sihwlog";

@Component({
  selector: 'stickyonder',
  template: `
    <div #stickycontainer [style.transform]="transform">
      <ng-content></ng-content>
    </div>`
  //het is niet ideaal om die div erom te hebben, die heeft een positie ten opzichte van de host-div (waardoor de hostdiv groter wordt als we deze laten zakken), maar gewoon met ElementRef werken gaat niet goed: die heeft geen hoogte
})
export class StickyOnderComponent implements AfterViewInit {
  public transform: string = 'none'; //de y-transform
  private log: Sihwlogger;
  private history = []; //houden we de vorige keren bij. Als meer dan zoveel keer in zoveel tijd, dan skippen we
  @ViewChild('stickycontainer') container;
  @Input('fullbrowser') fullbrowser: boolean = false; //als [fullbrowser]=true gegeven, dan kijken we niet naar de containerhoogte, maar naar de windowhoogte. Dit werkt beter in mobiele browsers, (chrome mobile geeft offsetheigth verkeerd, omdat 100vh niet klopt

  constructor(private Sihwlog: Sihwlog) {
    this.log = this.Sihwlog.logger();
  }

  ngAfterViewInit() {
    //op dit moment is de nativeElement er nog niet, en zeker geen offsetparent
    //dus we geven even
    setTimeout(() => {
      this.initObserver()
    }, 50);
  }

  /**
   * Doe het echte initwerk, als alles er is
   */
  initObserver(teller: number = 0) {
    if (teller === 100) {
      //we geven op
      this.log.warn(`stickyOnder: we geven op`);
      return;
    }
    if (!(this.container?.nativeElement?.parentElement?.parentElement)) {
      //straks weer
      setTimeout(() => {
        this.initObserver(teller + 1);
      }, 50);
      return;
    }

    let container = this.container.nativeElement;
    let parent = container.parentElement.parentElement; //de parent is onze host, dus daarboven
    this.log.debug(`stickyOnder`, container, parent);
    let win: any = <any>window; //even onttypen
    let ob:any;


    ob = new win.ResizeObserver(() => {
      this.log.debug('stickyonder: parent resize!');
      this.plaatsonderkant();
      this.log.debug('Einde parent resize');
    });
    ob.observe(parent);

    //en andere mutaties
    ob = new win.MutationObserver(()=> {
      this.log.debug(`stickyonder: parent change`);
      this.plaatsonderkant();
      this.log.debug(`einde parent change`);
    });
    ob.observe(parent, {subtree: true, childList: true, characterData: true});

    ob = null; //zodat de observers ge-gc'ed kunnen worden als dit element weg is
    this.plaatsonderkant();
    //en zometeen nog een keer
    setTimeout(() => this.plaatsonderkant(), 200);
  }

  private plaatsonderkant(): void {
    if (!(this.container?.nativeElement?.parentElement?.parentElement)) {
      this.log.debug(`Container of parent niet beschikbaar`);
      return;
    }
    let container = this.container.nativeElement;
    let parent = container.parentElement.parentElement;
    this.log.debug(`BEGIN PLAATSONDERKANT`);
    this.history.push(Date.now());
    if (this.history.length > 20) {
      let eerste = this.history.shift();
      if (Date.now() - eerste < 1000) {
        this.log.debug(`Al 20 keer in een seconde goed gezet. Skip`);
      }
      return;
    }
    let cheight = container.offsetHeight; //de hoogte van de container
    let ctop = container.offsetTop; //even
    let cbottom = ctop + cheight;
    let pheight = this.fullbrowser ? window.innerHeight : parent.offsetHeight;

    this.log.debug(`stickyonder: **** Plaats onderkant. pheight ${pheight}, ctop ${ctop}, cbottom ${cbottom}`);

    if (cbottom < pheight) {
      this.log.debug('stickyonder: Kan container mooi onderaan zetten');
      this.transform = `translateY(${pheight - cbottom}px)`; //de ruimte tussen de bottom en waar hij kan
      this.log.debug(this.transform);
    } else {
      //terug
      this.transform = 'none';
      this.log.debug('stickyonder: Kan onderbalk niet onderin scherm kwijt, gewoon onder de overige content');
    }
    this.log.debug('EINDE PLAATS ONDERKANT');
  }
}
